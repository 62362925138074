.container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.footer {
    padding-bottom: 80px;
    margin-top: auto;
}

.social {
    margin-top: 60px;
}