.container {
    padding-bottom: 40px;
    margin: 0 -10px;
}

.title {
    background: var(--color-gray-light-4);
    color: var(--color-gray-dark);
    font-size: 21px;
    text-align: center;
    line-height: 24px;
    padding: 11px 20px;
    margin: 0 0 16px;
}

.list {
    padding-bottom: 24px;
}

.input {
    padding: 0 10px;
}

.empty {
    font-size: 21px;
    font-weight: 500;
    text-align: center;
    line-height: 24px;
    padding: 20px 0 11px;
    margin: 0;
}

.loading {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
}

.loading p {
    margin: 0 0 20px;
}

.availableSoon {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    line-height: 20px;
    padding-top: 11px;
}

.subscriptionButton {
    text-align: center;
    padding: 20px 0;
}

.addChildDescription {
    max-width: 700px;
    margin-inline: auto;
    color: var(--color-primary);
    padding: 10px;
}

@media (min-width: 376px) {
    .title {
        border-radius: 10px;
    }
}
