.container {
    text-align: center;
    border-bottom: 1px solid rgba(46, 175, 190, 0.35);
    padding: 25px 0 30px;
}

.title {
    color: var(--color-primary);
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
    margin: 0;
}

.content {
    font-size: 16px;
    line-height: 18px;
    margin-top: 16px;
}

.content p {
    margin: 0;
}

.content p:not(.highlight) {
    margin-bottom: 5px;
}

.highlight {
    color: var(--color-primary);
    font-weight: 700;
}

.button {
    margin-top: 26px;
}

@media (min-width: 1200px) {
    .container {
        border: 0;
        padding: 0;
        margin-bottom: 50px;
    }

    .title {
        font-size: 38px;
        line-height: 42px;
    }

    .content {
        font-size: 24px;
        line-height: 27px;
        margin-top: 27px;
    }

    .button {
        margin-top: 35px;
    }
}