.container {
    margin-bottom: -20px;
}

.column {
    margin-bottom: 20px;
}

.empty {
    background: var(--color-gray-light-2);
    color: var(--color-black);
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    border-width: 1px 0;
    border-style: solid;
    border-color: var(--color-gray);
    padding: 8px 19px 9px;
    margin: 0 -10px 20px;
}

@media (min-width: 376px) {
    .empty {
        border-width: 1px;
        border-radius: 10px;
    }
}

@media (min-width: 1200px) {
    .container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 -25px;
    }

    .column {
        flex: 1;
        min-width: 33.33333333%;
        max-width: 33.33333333%;
        width: 33.33333333%;
        padding: 0 25px;
        margin-bottom: 50px;
    }

    .empty {
        font-size: 27px;
        text-align: center;
        line-height: 30px;
        padding-top: 9px;
        margin: 0 0 50px;
    }
}
