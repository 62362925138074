.container {
    font-size: 16px;
    text-align: center;
    line-height: 18px;
    padding-bottom: 3px;
    margin-top: -3px;
}

.container p {
    margin: 0 0 18px;
}