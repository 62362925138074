.ldsEllipsis {
    display: inline-block;
    position: relative;
    width: 36px;
    height: 8px;
}

.ldsEllipsis span {
    position: absolute;
    top: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color-primary);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ldsEllipsis.white span {
    background: var(--color-white);
}

.ldsEllipsis span:nth-child(1) {
    left: 0;
    animation: lds-ellipsis1 0.6s infinite;
}

.ldsEllipsis span:nth-child(2) {
    left: 0;
    animation: lds-ellipsis2 0.6s infinite;
}

.ldsEllipsis span:nth-child(3) {
    left: 14px;
    animation: lds-ellipsis2 0.6s infinite;
}

.ldsEllipsis span:nth-child(4) {
    left: 28px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(14px, 0);
    }
}
