.title {
    color: var(--color-primary);
    font-weight: 700;
    margin: 0;
}

p.title {
    font-size: 24px;
    line-height: 27px;
}

@media (min-width: 1200px) {
    .title,
    p.title {
        font-size: 38px;
        line-height: 42px;
    }
}