.validation {
    color: var(--color-gray-dark);
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    margin-top: 25px;
}

.validator {
    position: relative;
    display: inline-block;
    transition: color .3s ease;
}

.validator.active {
    color: var(--color-primary);
}

.validator:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    border-bottom: 1px solid var(--color-gray-dark);
    transition: width .3s ease, border .3s ease;
    z-index: 1;
}

.validator.active:before {
    width: 100%;
    border-color: var(--color-primary);
}