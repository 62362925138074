.container:not(:first-child) {
    padding-top: 22px;
}

.container:not(:last-child) {
    border-bottom: 1px solid rgba(46, 175, 190, 0.35);
    padding-bottom: 25px;
}

.title {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.items {
    margin-top: -4px;
}

.input {
    margin-top: 25px;
}

.empty {
    color: var(--color-gray-2);
    font-size: 16px;
    text-align: center;
    line-height: 18px;
    padding: 30px 0 6px;
}

.max {
    display: flex;
    justify-content: center;
}

.maxTitle {
    background: var(--color-gray);
    color: var(--color-white);
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    line-height: 18px;
    border-radius: 18px;
    box-shadow: var(--box-shadow-1);
    padding: 9px 20px 8px;
    margin-top: 25px;
}

.error {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-error);
    color: var(--color-white);
    font-size: 16px;
    text-align: center;
    line-height: 18px;
    overflow: hidden;
}

.errorBody {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 56px;
    padding: 10px;
}
