.container {
    padding-bottom: 10px;
}

.button {
    display: block;
    position: relative;
    height: calc(100% - 16px);
    background: transparent;
    color: var(--color-white);
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    text-decoration: none;
    border-radius: 23px;
    padding: 9px 20px 10px;
    margin-bottom: 16px;
    cursor: pointer;
    z-index: 1;
}

.button:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(46, 175, 190);
    background: -moz-linear-gradient(
        263deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: -webkit-linear-gradient(
        263deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: linear-gradient(
        263deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2eafbe",endColorstr="#2ac3bb",GradientType=1);
    border-radius: 23px;
    z-index: 1;
}

.text {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 40px;
    z-index: 1;
}

.difficulty {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    bottom: -16px;
    left: 6px;
    right: 6px;
    height: 34px;
    background: var(--color-white);
    box-shadow: var(--box-shadow-1);
    border-radius: 17px;
    padding: 4px 14px;
    z-index: -1;
}

.loading {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
}

@media (min-width: 1200px) {
    .button {
        font-size: 27px;
        text-align: center;
        line-height: 30px;
    }

    .text {
        justify-content: center;
        padding: 0 30px;
    }

    .difficulty {
        right: 8px;
        bottom: -20px;
        left: 8px;
        height: 38px;
        padding: 5px 15px;
    }

    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        top:-19px;
        right: -30px;
        width: 50px;
        height: 50px;
        background: var(--color-white);
        border: 1px solid var(--color-primary);
        border-radius: 50%;
        box-shadow: var(--box-shadow-1);
        transform: none;
    }
}