.container {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.flex {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.main {
    padding-bottom: 30px;
}

.main.isDesktop {
    padding-bottom: 12.96296296vh;
}

.footer {
    margin-top: auto;
    z-index: -1;
}

.footer.isDesktop {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 0;
}

.wrapper {
    max-width: 375px;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}

.wrapper.default {
    padding-top: 68px;
}

.wrapper.default.isDesktop {
    padding-top: 12.96296296vh;
}

.wrapper.top {
    padding-top: 80px;
}

.wrapper.top.isDesktop {
    padding-top: 11.11111111vh;
}

.wrapper.center {
    padding-top: 96px;
}

.wrapper.center.isDesktop {
    padding-top: 18.51851851vh;
}

.footer .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.image {
    display: block;
    width: auto;
    align-self: flex-start;
    max-width: 100%;
    height: auto;
}

.footer.isDesktop .image {
    max-width: none;
}

.image.center {
    align-self: center;
}

.image.right {
    align-self: flex-end;
}
