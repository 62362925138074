.back {
    position: absolute;
    top: 10px;
    left: 10px;
    line-height: 0;
    z-index: 200;
}

.form {
    text-align: center;
}

.validator {
    margin-top: 15px;
}

.formButton {
    margin-top: 35px;
    padding-bottom: 10px;
}
