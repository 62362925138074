.container {
    padding-bottom: 60px;
}

.button {
    text-align: center;
    margin-top: 30px;
}

.loading {
    text-align: center;
}

@media (min-width: 1200px) {
    .container {
        padding-bottom: 80px;
    }
    .button {
        margin-top: 50px;
    }
}