.container {
    position: relative;
    display: flex;
    background: var(--color-white);
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 16px;
    color: var(--color-white);
    border: 1px solid var(--color-error);
    border-radius: 28px;
    padding: 0;
    margin: 50px auto;
    max-width: 320px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 54px;
    max-width: 54px;
    width: 54px;
    background: var(--color-white);
    border: 1px solid var(--color-error);
    border-radius: 28px;
    box-shadow: var(--box-shadow-1);
    margin: -1px 0 -1px -1px;
}

.content {
    flex: 1;
    padding: 15px 25px 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.content p {
    margin: 0;
}

.content a {
    color: inherit;
    font-weight: 600;
    text-decoration: none;
}

.red {
    color: var(--color-error);
}

.primary {
    color: var(--color-primary);
}
