.notificationOuter {
    position: relative;
}

.notification {
    cursor: pointer;
    display: block;
    width: 100%;
    padding: 17px 0 17px 50px;
    border: 0;
    border-bottom: 1px var(--color-primary-2) solid;
    background-color: transparent;
}

.notificationInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date {
    display: flex;
    align-items: center;
    gap: 30px;
}

.time {
    font-size: 34px;
    line-height: 38px;
    font-weight: 500;
}

.time > span + span {
    margin-left: 15px;
}

.buttonRemove {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-error);
    border: 1px var(--color-error) solid;
    border-radius: 5px;
    background-color: transparent;
    font-weight: 700;
}
