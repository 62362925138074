.container {
    display: flex;
    margin-left: -5px;
}

.column {
    padding-left: 5px;
}

.column.tasks {
    min-width: 56.6197183%;
    max-width: 56.6197183%;
    width: 56.6197183%;
}

.column.theory {
    min-width: 43.3802817%;
    max-width: 43.3802817%;
    width: 43.3802817%;
}

@media (min-width: 1200px) {
    .container {
        margin-left: -10px;
    }
    .column {
        padding-left: 10px;
    }
}