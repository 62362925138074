.container {
    position: relative;
    display: inline-block;
    z-index: 1000;
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}