.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-gray-2);
    font-size: 16px;
    line-height: 18px;
    padding: 18px 10px 17px;
}

.container + .container {
    border-top: 1px solid var(--color-primary-light-2);
}

.number {
    flex: 1;
}

.button {
    display: block;
    background: transparent;
    color: var(--color-error);
    font-size: 14px;
    line-height: 16px;
    text-decoration: underline;
    border: 0;
    box-shadow: none;
    outline: 0;
    padding: 0;
    cursor: pointer;
}

.button:hover {
    text-decoration: none;
}
