.container {
    display: inline-block;
    position: relative;
}

.bars {
    display: flex;
}

.bar {
    width: 15px;
    height: 8px;
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 4px;
}

.bar.easy {
    background: var(--color-success);
    border-color: var(--color-success);
}

.bar.medium {
    background: var(--color-warning);
    border-color: var(--color-warning);
}

.bar.hard {
    background: var(--color-error);
    border-color: var(--color-error);
}

.bar.advanced {
    border-color: var(--color-primary);
}

.bar.advanced.fill {
    background-color: var(--color-primary);
}

.bar + .bar {
    margin-left: 1px;
}

.text {
    position: absolute;
    top: 100%;
    left: 50%;
    color: var(--color-primary);
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    line-height: 14px;
    white-space: nowrap;
    margin-top: 1px;
    transform: translateX(-50%);
}

.text.easy {
    color: var(--color-success);
}

.text.medium {
    color: var(--color-warning);
}

.text.hard {
    color: var(--color-error);
}

.text.horizontal {
    top: 50%;
    right: 100%;
    left: auto;
    transform: translateY(-50%);
    margin-top: -1px;
    margin-right: 5px;
}

@media (min-width: 1200px) {
    .container.large .bar {
        width: 20px;
        height: 10px;
        border-radius: 5px;
    }

    .container.large .bar + .bar {
        margin-left: 2px;
    }

    .container.large .text {
        font-size: 16px;
        line-height: 18px;
        margin-top: 0;
    }
}
