.button {
    display: inline-block;
    background: transparent;
    color: var(--color-primary);
    font-size: 10px;
    font-weight: 500;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 12px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding: 0;
    margin-top: 5px;
    cursor: pointer;
}

.button.disabled {
    pointer-events: none;
}

.interior {
    display: flex;
    align-items: center;
}

.button.reversed .interior {
    flex-direction: row-reverse;
}

.aside {
    min-width: 36px;
    max-width: 36px;
    width: 36px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: var(--color-white);
    border-radius: 50%;
}

.text {
    margin-left: 5px;
}

.button.reversed .text {
    margin-left: 0;
    margin-right: 5px;
}

@media (min-width: 1200px) {
    .button {
        display: block;
        margin: 0;
    }
    .icon {
        box-shadow: var(--box-shadow-1);
    }
}
