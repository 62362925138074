.container {
    position: relative;
    margin: 0 -10px;
    z-index: 1;
}

.container:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 10px;
    left: 0;
    width: 100%;
    background: var(--color-white);
    opacity: 0;
    transition: opacity .3s ease;
    z-index: -1;
}

.container.open:before {
    opacity: 1;
}

.button {
    position: relative;
    display: block;
    width: 100%;
    min-height: 50px;
    background: transparent;
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    line-height: 18px;
    border: 0;
    border-radius: 0;
    padding: 16px 46px 16px 50px;
    cursor: pointer;
}

.button:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
    border-bottom: 1px solid #b4dde3;
    transition: opacity .3s ease;
}

.container.open .button:after {
    opacity: 0;
}

.icon {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 24px;
    margin-top: -15px;
}

.icon svg {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
}

.badge {
    position: absolute;
    top: 50%;
    left: 50%;
    color: var(--color-white);
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    line-height: 1;
    transform: translate(-50%, -50%);
}

.badge img {
    width: auto;
    max-width: 16px;
    height: auto;
}

.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 10px;
    width: 26px;
    height: 26px;
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    margin-top: -13px;
}

.collapse {
    overflow: hidden;
    padding: 0 10px;
}

.body {
    padding: 5px 0 20px;
}

@media (min-width: 376px) and (max-width: 1199px) {
    .container:before {
        border-radius: 10px;
    }
}

@media (min-width: 1200px) {
    .container {
        margin: 0;
    }

    .container:before {
        bottom: 0;
        background: var(--color-gray-3);
    }

    .container:first-child:before {
        border-radius: 10px 10px 0 0;
    }

    .container:last-child:before {
        border-radius: 0 0 10px 10px;
    }

    .button {
        font-size: 24px;
        line-height: 27px;
        padding: 26px 50px 27px 100px;
    }

    .button:after {
        right: 20px;
        left: 20px;
    }

    .icon {
        left: 20px;
        width: 44px;
        margin-top: -28px;
    }

    .badge {
        font-size: 26px;
    }

    .badge img {
        max-width: 30px;
    }

    .body {
        position: relative;
    }

    .body:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid #b4dde3;
    }

    .container:last-child .button:after,
    .container:last-child .body:after  {
        content: none;
    }

    .arrow {
        right: 20px;
        width: 37px;
        height: 37px;
        background: var(--color-white);
        margin-top: -18.5px;
    }

    .arrow svg {
        width: 15px;
    }

    .collapse {
        padding: 0 20px;
    }

    .container:not(:last-child) .body {
        padding-bottom: 21px;
    }
}