.container {
    padding-bottom: 60px;
    margin-top: -25px;
}

.form {
    text-align: center;
}

.inputGroup {
    margin: 25px -2px 0;
}

.inputGroup > div {
    padding: 0 2px;
}

.formButton {
    margin-top: 35px;
}

.cancelButton {
    margin-top: 25px;
}

.cancelButton a {
    background: var(--color-error);
}

@media (min-width: 1200px) {
    .container {
        padding: 0;
    }
}
