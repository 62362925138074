.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 152px;
    background: rgb(46, 175, 190);
    background: -moz-linear-gradient(
        260deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: -webkit-linear-gradient(
        260deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: linear-gradient(
        260deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2eafbe",endColorstr="#2ac3bb",GradientType=1);
    margin-bottom: 40px;
}

.wrapper {
    width: 100%;
    max-width: 1340px;
    padding-right: 30px;
    padding-left: 30px;
}

.title {
    color: var(--color-white);
    margin: 0;
}
