.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.brand {
    padding-left: 30px;
}

.notification {
    padding-right: 15px;
}