.colorButton {
    position: relative;
    cursor: pointer;
    padding: 0;
    background: transparent;
    border: 0;

}

.colorButton > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border: 3px solid transparent;
    border-radius: 50%;
}
