.heading {
    text-align: center;
    margin-top: 40px;
}

.forwardButton {
    text-align: center;
    margin-top: 40px;
}

.forwardButton button {
    background: var(--color-error);
    min-width: 230px;
}