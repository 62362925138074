.button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: rgb(46,175,190);
    background: -moz-linear-gradient(262deg, rgba(46,175,190,1) 0%, rgba(42,195,187,1) 100%);
    background: -webkit-linear-gradient(262deg, rgba(46,175,190,1) 0%, rgba(42,195,187,1) 100%);
    background: linear-gradient(262deg, rgba(46,175,190,1) 0%, rgba(42,195,187,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2eafbe",endColorstr="#2ac3bb",GradientType=1); 
    color: var(--color-white);
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    border: 0;
    border-radius: 18px;
    box-shadow: var(--box-shadow-1);
    outline: 0;
    padding: 9px 20px;
    transition: opacity .3s ease;
    cursor: pointer;
}

.button:hover {
    opacity: .7;
}

.button.alternative {
    background: var(--color-white);
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    padding: 8px 19px;
}

.button.important {
    background: var(--color-error);
}

.button[disabled] {
    background: var(--color-gray);
    pointer-events: none;
}

.button.link:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    width: 12px;
    height: 10px;
    background: url(./images/link-icon.svg);
    background-size: cover;
    background-position: center;
    margin-top: -5px;
    z-index: 1;
}

.button.link.alternative:after {
    background: url(./images/link-icon_alternative.svg);
}

.icon {
    display: flex;
    align-items: center;
    height: 17px;
}

.icon img,
.icon svg {
    width: auto;
    max-width: 30px;
    height: auto;
    max-height: 17px;
}

.icon + .text {
    padding-left: 14px;
}

.button[disabled] .icon,
.button[disabled] .text,
.button.link[disabled]:after {
    opacity: .8;
}