.step4 {
    max-width: 630px;
    margin-inline: auto;
}

.step4 p {
    margin-bottom: 20px;
    text-align: center;
}

.heading {
    margin-bottom: 40px;
    text-align: center;
}

.buttons {
    padding-top: 40px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

@media (min-width: 1200px) {
    .step4 p {
        max-width: 435px;
        margin-inline: auto;
        padding: 65px 50px;
        border: 1px solid var(--color-primary);
        border-radius: 20px;
        font-size: 24px;
        line-height: 27px;
    }

    .heading {
        text-transform: uppercase;
        font-weight: 600;
    }
}
