.picker {
    display: flex;
    padding-block: 21px;
    max-width: 450px;
    margin-inline: auto;
}

.picker > * {
    flex: 0 1 50%;
}

.pickerInner {
    display: flex;
    gap: 44px;
}

.progressBar {
    display: flex;
    align-items: flex-end;
    background-color: var(--color-white);
    border: 1px var(--color-primary) solid;
    border-radius: 6px;
    width: 11px;
    height: 200px;
}

.fill {
    border-radius: 6px;
    background-color: var(--color-primary);
    flex-grow: 1;
}

.pickerButton {
    cursor: pointer;
    border: 0;
    border-radius: 50%;
    background-color: var(--color-primary);
    height: 45px;
    width: 45px;
}

.pickerButton:disabled {
    background-color: var(--color-gray);
}

.arrowUp {
    transform: rotate(90deg);
    width: 12px;
}

.arrowDown {
    transform: rotate(270deg);
    width: 12px;
}

.pickerValue {
    margin-block: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    width: 65px;
    color: var(--color-primary);
    background-color: var(--color-white);
    font-size: 26px;
    line-height: 29px;
    box-shadow: var(--box-shadow-2);
    border-radius: 10px;
    font-weight: 600;
}

.robotImg {
    max-height: 200px;
}
