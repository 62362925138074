.custom-editor .ck.ck-editor__top {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    border-radius: 0 !important;
    overflow: hidden !important;
    padding: 15px !important;
    pointer-events: none !important;
    z-index: 10 !important;
}

.custom-editor.is-answered .ck.ck-editor__top:before {
    opacity: 0 !important;
}

.custom-editor .ck.ck-sticky-panel,
.custom-editor .ck.ck-sticky-panel__content,
.custom-editor .ck.ck-toolbar,
.custom-editor .ck.ck-toolbar__items,
.custom-editor .ck.ck-dropdown {
    display: block !important;
    padding: 0 !important;
    margin: 0 !important;
}

.custom-editor
    .ck-rounded-corners
    .ck.ck-editor__top
    .ck-sticky-panel
    .ck-toolbar,
.custom-editor
    .ck.ck-editor__top
    .ck-sticky-panel
    .ck-toolbar.ck-rounded-corners {
    background: transparent !important;
    border: 0 !important;
}

.custom-editor .ck.ck-button:active,
.custom-editor .ck.ck-button:focus,
.custom-editor a.ck.ck-button:active,
.custom-editor a.ck.ck-button:focus {
    border: 0 !important;
    box-shadow: none !important;
}

.custom-editor .ck.ck-button,
.custom-editor a.ck.ck-button {
    display: block !important;
    width: 30px !important;
    height: 30px !important;
    background-color: var(--color-primary);
    background-image: url(./images/button-icon.svg);
    background-size: 18px 18px;
    background-position: center;
    background-repeat: no-repeat;
    border: 0 !important;
    border-radius: 6px !important;
    padding: 0 !important;
    margin: 0 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
}

.custom-editor.is-set .ck.ck-button,
.custom-editor.is-set a.ck.ck-button {
    pointer-events: none !important;
}

.custom-editor .ck.ck-button:not(.ck-disabled):hover,
.custom-editor a.ck.ck-button:not(.ck-disabled):hover,
.custom-editor .ck.ck-button:not(.ck-disabled):active,
.custom-editor a.ck.ck-button:not(.ck-disabled):active {
    background-color: var(--color-primary) !important;
    background-image: url(./images/button-icon.svg);
    background-size: 18px 18px;
    background-position: center;
    background-repeat: no-repeat;
}

.custom-editor .ck.ck-button svg {
    display: none !important;
}

.custom-editor .ck.ck-editor__main {
    position: relative !important;
    background-color: var(--color-white) !important;
    background-image: url(./images/grid-bg.png) !important;
    background-size: 10px 10px !important;
    background-position: center !important;
    background-repeat: repeat !important;
    border-radius: 23px !important;
    box-shadow: var(--box-shadow-1) !important;
    overflow: hidden !important;
    z-index: 1 !important;
}

.custom-editor .ck.ck-editor__main:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 82px;
    height: 84px;
    background: url(images/background-icon.svg);
    background-size: cover;
    background-position: center;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.custom-editor .ck.ck-editor__main > .ck-editor__editable {
    height: 125px !important;
    background: transparent !important;
    text-align: center !important;
    overflow: hidden !important;
    padding: 0 !important;
}

.custom-editor .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border: 0 !important;
}

.custom-editor .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.custom-editor .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border: 0 !important;
    box-shadow: none !important;
}

.custom-editor .ck.ck-editor__main > .ck-editor__editable > p {
    height: 100% !important;
    max-width: 100% !important;
    font-size: 0 !important;
    line-height: 0 !important;
    word-break: break-word !important;
    margin: 0 !important;
}

.custom-editor
    .ck.ck-editor__main
    > .ck-editor__editable
    > p
    > .ck-math-widget {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 16px !important;
    line-height: 20px !important;
    height: 100% !important;
    padding: 0 !important;
    cursor: pointer !important;
}

.custom-editor
    .ck.ck-editor__main
    > .ck-editor__editable
    > p
    > .ck-math-widget
    > img {
    width: auto !important;
    max-width: 100% !important;
    height: auto !important;
}

.custom-editor .ck .ck-widget,
.custom-editor .ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected,
.custom-editor
    .ck.ck-editor__editable.ck-blurred
    .ck-widget.ck-widget_selected:hover,
.custom-editor .ck .ck-widget.ck-widget_selected,
.custom-editor .ck .ck-widget.ck-widget_selected:hover {
    outline: none !important;
}

.wrs_modal_button_accept:disabled {
    cursor: default;
    opacity: 0.5;
}

@media (min-width: 1200px) {
    /* .custom-editor .ck.ck-editor__main {
        background-size: 20px 20px !important;
    }

    .custom-editor .ck.ck-editor__main > .ck-editor__editable {
        height: 230px !important;
    }

    .custom-editor .ck.ck-editor__main:before {
        width: 144px;
        height: 148px;
    }

    .custom-editor .ck.ck-toolbar {
        padding: 20px !important;
    } */
}
