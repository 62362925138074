.container {
    padding-bottom: 30px;
    margin-top: 13px;
}

.button {
    text-align: center;
    margin: 25px auto 0;
}

@media (min-width: 1200px) {
    .container {
        width: 700px;
        background: var(--color-white);
        border: 1px solid var(--color-primary);
        border-radius: 20px;
        padding: 28px 20px 30px;
        margin-right: auto;
        margin-left: auto;
    }
}