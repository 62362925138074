.root {
    height: 100%;
    overflow-y: auto;
}

.container {
    max-width: 1100px;
    margin-inline: auto;
}

.padding {
    padding: 15px;
}
