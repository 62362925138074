.back {
    position: absolute;
    top: 10px;
    left: 10px;
    line-height: 0;
    z-index: 200;
}

.heading {
    text-align: center;
}

.buttons {
    margin-top: 40px;
}

.buttons button {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.buttons button + button {
    margin-top: 20px;
}

.changeButton {
    min-width: 210px;
}

@media (min-width: 1200px) {
    .container {
        margin: 0 -260px;
    }

    .heading {
        margin-bottom: 51px;
    }

    .heading h2 {
        font-size: 32px;
        line-height: 36px;
    }

    .buttons {
        margin-top: 56px;
    }
}
