.heading {
    text-align: center;
    margin-top: 40px;
}

.buttonGroup {
    display: flex;
    align-items: center;
    max-width: 250px;
    margin: 40px auto 0;
}

.buttonGroup>div {
    padding: 0 5px;
}

.reloadButton button {
    width: 165px;
}

.debugButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 45px;
    background: var(--color-error);
    border: 0;
    border-radius: 23px;
    box-shadow: var(--box-shadow-2);
    outline: 0;
    padding: 0;
    cursor: pointer;
}

.debugButton img {
    max-height: 24px;
}