.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    padding-bottom: 30px;
}

.main {
    padding-bottom: 30px;
}

.title {
    color: var(--color-primary);
    font-size: 24px;
    line-height: 28px;
    margin: 34px 0 0;
}

.buttons {
    max-width: 230px;
    padding-top: 15px;
    margin: 0 auto;
}

.button {
    margin-top: 20px;
}

.button button,
.button a {
    display: block;
    width: 100%;
}

.robot {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 156px;
    margin-top: auto;
}

.robot img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 156px;
}

@media (min-width: 1200px) {
    .container {
        display: block;
        max-width: 660px;
        margin: 0 auto;
        padding-bottom: 0;
    }

    .main {
        padding-bottom: 80px;
    }

    .title {
        font-size: 38px;
        line-height: 42px;
        margin: 52px 0 0;
    }

    .buttons {
        padding-top: 42px;
    }

    .button {
        margin-top: 30px;
    }
}
