.container {
    margin-bottom: 20px;
}

.row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: -5px;
}

.row.correct {
    overflow: hidden;
}

.column {
    display: flex;
    justify-content: center;
    min-width: 50%;
    max-width: 50%;
    width: 50%;
    color: var(--color-black);
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    line-height: 16px;
    padding-left: 5px;
}

.result {
    width: 115px;
    color: var(--color-success);
    text-align: center;
    font-weight: 700;
}

.result p {
    margin: 0;
}

.row.triple .column {
    min-width: 33.33333333%;
    max-width: 33.33333333%;
    width: 33.33333333%;
}

.column.first {
    justify-content: flex-end;
    text-align: right;
}

.column.last {
    justify-content: flex-start;
    text-align: left;
}

.column p {
    margin: 0;
}

.column figure {
    margin: 0; 
}

.column img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
}

.fill,
.answer {
    position: relative;
    display: block;
    width: 100%;
    max-width: 115px;
    height: 42px;
    background: var(--color-white);
    color: var(--color-black);
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    line-height: 16px;
    border: 1px solid var(--color-white);
    border-radius: 20px;
    box-shadow: var(--box-shadow-1);
    outline: 0;
    overflow: hidden;
    padding: 0;
}

.fill {
    cursor: pointer;
}

.fill.disabled,
.answer.disabled {
    pointer-events: none;
}

.fillContent,
.answerContent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    word-break: break-word;
    padding: 0 10px;
    pointer-events: none;
}

.fillContent svg,
.answerContent svg {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
}

.answers {
    padding-top: 10px;
}

.title {
    color: var(--color-black);
    font-size: 16px;
    line-height: 18px;
    margin: 0 0 10px;
}

.list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -5px;
}

.item {
    min-width: 33.33333333%;
    max-width: 33.33333333%;
    width: 33.33333333%;
    height: 42px;
    padding-left: 5px;
    margin-bottom: 10px;
}

@media (min-width: 1200px) {
    .container {
        max-width: 355px;
        margin-right: auto;
        margin-left: auto;
    }
}
