.container {
    display: flex;
    justify-content: right;
    margin-bottom: 10px;
}

.radio {
    display: flex;
    align-items: center;
    background: var(--color-white);
    border: 1px solid var(--color-primary);
    border-radius: 20px;
    padding: 2px;
}

.button {
    position: relative;
    display: flex;
    align-items: center;
    background: transparent;
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    font-weight: 700;
    border: 0;
    border-radius: 20px;
    padding: 3px 10px;
    cursor: pointer;
    z-index: 1;
}

.icon {
    display: flex;
    align-items: center;
    height: 10px;
    margin-right: 5px;
}

.icon img,
.icon svg {
    width: auto;
    max-width: 16px;
    height: auto;
    max-height: 10px;
}

.highlight {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-primary);
    border-radius: 20px;
    z-index: -1;
}
