.button {
    cursor: pointer;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    border: 2px solid var(--color-white);
    border-radius: 10px;
    box-shadow: var(--box-shadow-2);
    padding: 18px 10px;
    transition: border 0.2s linear;
}

.button:hover,
.button.selected {
    border-color: var(--color-primary);
}
