.container {
    margin-top: 50px;
}

.title {
    font-weight: 300;
    margin: 0 0 20px;
}

.tiles {
    display: flex;
    margin: 0 -8px;
}

.tile {
    flex: 1;
    min-width: 33.33333333%;
    max-width: 33.33333333%;;
    width: 33.33333333%;
    padding: 0 8px;
    margin-bottom: 10px;
}

.item {
    display: block;
    text-decoration: none;
}

.item.unfinished {
    opacity: .5;
}

.itemIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    background: var(--color-white);
    border: 2px solid var(--color-white);
    border-radius: 10px;
    box-shadow: var(--box-shadow-2);
    transition: border .3s ease;
}

.item:hover .itemIcon {
    border-color: var(--color-primary);
}

.itemIcon svg {
    display: block;
    width: auto;
    max-width: 54px;
    height: auto;
    max-height: 54px;
}

.itemCaption {
    display: block;
    color: var(--color-primary);
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    line-height: 17px;
    margin-top: 5px;
}

.alert {
    background: var(--color-error);
    color: var(--color-white);
    font-size: 14px;
    line-height: 18px;
    border-radius: 10px;
    padding: 8px 15px;
    margin-bottom: 21px;
}

.alert p {
    margin: 0;
}