.container {
    padding-bottom: 80px;
}

.title {
    position: relative;
    background: var(--color-gray-light-4);
    color: var(--color-gray-dark);
    font-size: 27px;
    line-height: 30px;
    text-align: center;
    border-radius: 10px;
    padding: 8px 51px;
    margin: 0;
}

.scrollToButton {
    position: absolute;
    top: 50%;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: var(--color-primary);
    border: 0;
    border-radius: 50%;
    margin-top: -18px;
    transition: opacity 0.3s ease;
    cursor: pointer;
}

.scrollToButton:hover {
    opacity: 0.7;
}

.scrollToButton svg {
    width: auto;
    max-width: 14px;
    height: auto;
    max-height: 14px;
}

.invite {
    border-bottom: 3px solid var(--color-gray-light-4);
    padding: 30px 0;
}

.addChildDescription {
    max-width: 700px;
    margin-inline: auto;
    color: var(--color-primary);
    padding: 10px;
}

.input {
    max-width: 355px;
    margin: 0 auto;
}

.empty {
    font-size: 21px;
    font-weight: 500;
    text-align: center;
    line-height: 24px;
    border-bottom: 3px solid var(--color-gray-light-4);
    padding: 46px 20px 45px;
}

.loading {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
}

.loading p {
    margin: 0 0 20px;
}

.subscriptionButton {
    text-align: center;
    padding: 30px 0;
}

.subscriptionButton a,
.subscriptionButton button {
    min-width: 230px;
}

.loadingMask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}