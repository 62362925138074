.logo {
    display: flex;
    align-items: center;
    height: 40px;
}

.logo img {
    width: auto;
    max-width: 100px;
    height: auto;
    max-height: 40px;
}