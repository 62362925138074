.container {
    color: var(--color-black);
    text-align: center;
}

.container.inline {
    margin: -3px 0 20px;
}

.container p {
    margin: 0;
}

.container p.blank {
    padding-bottom: 17px;
}

.container p.blank + figure {
    padding-top: 3px;
}

.container figure {
    display: block;
    padding-bottom: 4px;
    margin: 0;
}

.container .table > table {
    width: 100%;
    border-collapse: collapse;
    word-break: break-word;
}

.container .table > table th,
.container .table > table td {
    font-size: 14px;
    line-height: 16px;
    border: 1px solid var(--color-primary);
    padding: 4px 8px;
}

.container .table > table th {
    background: rgba(46, 175, 190, 0.4);
}

.container .table > table td {
    background: rgba(255, 255, 255, 0.64);
}

.container .table > table p {
    margin: 0;
}

.container ul,
.container ol {
    text-align: left;
    margin: 0;
    padding-left: 15px;
}

.container ul > li + li,
.container ol > li + li {
    margin-top: 5px;
}

.container iframe {
    left: 0;
}

.container img,
.container svg {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}

.container mjx-container svg {
    display: inline-block;
}

.container .media > div > div {
    padding-top: 177.778% !important;
}

@media (max-width: 1199px) {
    .container .image {
        width: 100% !important;
    }
}
