.version {
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--color-white);
    color: var(--color-gray-dark);
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
    border: 1px solid var(--color-primary);
    border-bottom: 0;
    border-left: 0;
    border-radius: 0 6px 0 0;
    opacity: 0.6;
    padding: 1px 6px 2px 5px;
    transition: opacity 0.3s ease;
    cursor: default;
    z-index: 99999;
}

.version:hover {
    opacity: 1;
}
