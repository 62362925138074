.container {
    flex: 1;
    padding-bottom: 46px;
}

.container.flex {
    display: flex;
    flex-direction: column;
}

.body {
    padding-bottom: 30px;
}

.footer {
    padding-bottom: 5px;
    margin-top: auto;
}