.schedule {
    text-align: center;
    position: relative;
    margin-bottom: 46px;
}

.loader {
    width: fit-content;
    margin-inline: auto;
}

.subtitle {
    display: none;
}

.bold {
    font-weight: 700;
    color: var(--color-primary);
}

.info {
    margin-bottom: 5px;
}

.title {
    font-size: 24px;
    line-height: 27px;
    font-weight: 600;
    color: var(--color-primary);
    max-width: 240px;
    margin-inline: auto;
    margin-top: 30px;
}

.card {
    color: var(--color-primary);
    background-color: var(--color-white);
    padding: 10px;
    font-size: 18px;
    line-height: 20px;
    border: 1px var(--color-gray-2) solid;
    border-radius: 12px;
    margin-bottom: 30px;
    max-width: 600px;
    margin-inline: auto;
}

@media (min-width: 1200px) {
    .subtitle {
        display: block;
        font-size: 38px;
        line-height: 42px;
        color: var(--color-primary);
        text-align: center;
        margin-bottom: 30px;
    }

    .info {
        font-size: 24px;
        line-height: 27px;
    }

    .title {
        margin-top: 50px;
    }

    .card {
        margin-top: 10px;
        margin-bottom: 50px;
    }
}
