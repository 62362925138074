.dayButtons {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.dayButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 50%;
    box-shadow: var(--box-shadow-2);
    width: 45px;
    height: 45px;
    color: var(--color-black);
    background-color: var(--color-white);
    transition: background-color 150ms ease, color 150ms ease;
}

.dayButton.selected {
    color: var(--color-white);
    background-color: var(--color-primary);
}
