.container {
    padding-bottom: 30px;
}

.options {
    margin-top: -40px;
}

@media (min-width: 1200px) {    
    .options {
        padding-top: 5px;
        margin-top: 0;
    }
}