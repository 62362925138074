.container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--color-gray-light-4);
    padding: 10px 0;
    z-index: 1000;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 375px;
    padding: 0 10px;
    margin: 0 auto;
}

.button {
    display: flex;
    align-items: center;
    min-height: 26px;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    line-height: 16px;
    border: 0;
    border-radius: 18px;
    box-shadow: var(--box-shadow-1);
    outline: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: opacity 0.3s ease;
    cursor: pointer;
}

.button:hover,
.button:focus-visible {
    opacity: 0.8;
}

.button.backward {
    padding-right: 20px;
    padding-left: 15px;
}

.button.forward {
    padding-right: 15px;
    padding-left: 20px;
}

.button.backward.loading,
.button.forward.loading {
    padding-left: 20px;
    padding-right: 20px;
}

.button[disabled],
.button.disabled {
    pointer-events: none;
}

.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 6px;
}

.button.backward .name {
    padding-left: 10px;
}

.button.forward .name {
    padding-right: 10px;
}

.loadingMask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
}

@media (min-width: 1200px) {
    .wrapper {
        max-width: 1340px;
        padding: 0 30px;
    }

    .wrapper.narrow {
        max-width: 760px;
    }
}
