.message {
    position: relative;
    background: var(--color-error);
    color: var(--color-white);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 18px;
    border-radius: 17px;
    padding: 8px 15px 8px 46px;
    margin: 0;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
    background: var(--color-white);
    border: 1px solid var(--color-error);
    box-shadow: 3px 0 6px rgba(0, 0, 0, .15);
    border-radius: 17px;
}