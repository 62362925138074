.tasks {
    display: flex;
    flex-direction: column;
}

.empty {
    background: var(--color-gray-light-2);
    color: var(--color-black);
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    line-height: 20px;
    border-radius: 20px;
    box-shadow: var(--box-shadow-1);
    padding: 10px 20px;
    margin-bottom: 10px;
}

@media (min-width: 1200px) {
    .tasksGrid {
        display: grid;
        grid-auto-columns: 33.3333%;
        grid-auto-flow: column;
        margin: 0 -40px;
    }

    .tasksGrid > div {
        margin: 0 40px;
    }

    .empty {
        width: calc(300% + 160px);
    }
}
