.container {
    position: relative;
    border-bottom: 3px solid var(--color-gray-light-4);
    padding-bottom: 5px;
    margin-bottom: 16px;
}

.heading {
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.content {
    color: var(--color-dark-gray);
    flex: 1;
}

.content.unconfirmed {
    color: var(--color-gray);
}

.name {
    font-size: 21px;
    line-height: 24px;
    word-break: break-word;
    margin: 0;
}

.class {
    font-size: 16px;
    font-style: italic;
    line-height: 18px;
    margin: 0;
}

.buttonGroup {
    display: flex;
    align-items: center;
}

.button {
    padding-left: 10px;
}

.accordion {
    margin-top: 16px;
}

.wrapper {
    padding: 0 20px;
}

.link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 35px;
    background: rgb(46, 175, 190);
    background: -moz-linear-gradient(
        264deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: -webkit-linear-gradient(
        264deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: linear-gradient(
        264deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2eafbe",endColorstr="#2ac3bb",GradientType=1);
    color: var(--color-white);
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    line-height: 18px;
    border-radius: 18px;
    box-shadow: var(--box-shadow-1);
    outline: 0;
    padding: 9px 20px 8px;
    margin-bottom: 15px;
    cursor: pointer;
}

.link.disabled {
    background: var(--color-gray);
    pointer-events: none;
}

.link:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    width: 12px;
    height: 10px;
    background: url(../../images/dashboard/parent/link-icon.svg);
    background-size: cover;
    background-position: center;
    margin-top: -5px;
}

.linkIcon {
    display: flex;
    align-items: center;
    height: 18px;
}

.linkIcon img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 18px;
}

.linkTitle {
    padding-left: 15px;
}

.error {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -6px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    background: var(--color-error);
    color: var(--color-white);
    text-align: center;
    border-radius: 10px;
    padding: 0 10px;
    z-index: 1;
}

.error p {
    margin: 0;
}