.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -20px -15px 0;
}

.item {
    width: 160px;
    padding: 0 15px;
}

.button {
    display: block;
    width: 100%;
    background: transparent;
    color: var(--color-dark-gray);
    font-size: 16px;
    line-height: 18px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding: 0 0 6px;
    margin-top: 20px;
    cursor: pointer;
}

.button[disabled] {
    pointer-events: none;
}

.box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
    background: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 20px;
    box-shadow: var(--box-shadow-2);
    transition: border 0.3s ease;
}

.button:hover .box {
    border-color: var(--color-primary);
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 50px;
}

.icon img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 50px;
}

.icon2 {
    position: absolute;
    bottom: 9px;
    right: 9px;
}

.icon2 img {
    display: block;
    width: auto;
    max-width: 50px;
    height: auto;
    max-height: 30px;
}

.label {
    display: block;
    padding-top: 9px;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    background: rgba(0, 0, 0, .6);
    border-radius: 20px;
    z-index: 10;
}

@media (min-width: 1200px) {
    .item {
        width: 180px;
    }
}
