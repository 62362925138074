.container {
    padding-bottom: 45px;
    margin-top: 40px;
}

.container.preview {
    padding-bottom: 95px;
    margin-top: -24px;
}

@media (min-width: 1200px) {
    .container {
        padding-bottom: 15px;
    }
}
