.button {
    position: relative;
    cursor: pointer;
    padding: 0;
    background: var(--color-white);
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

.button > span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: 2px solid white;
    border-radius: 50%;
}

.active {
    background-color: var(--color-primary);
}
