.container {
    margin-top: -5px;
}

@media (max-width: 1199px) {
    .container {
        padding-bottom: 80px;
    }
    
    .bar {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: var(--color-gray-light-4);
        padding: 20px 0;
    }

    .input {
        max-width: 375px;
        padding-right: 10px;
        padding-left: 10px;
        margin: 0 auto;
    }
}
