.container {
    position: relative;
    display: flex;
    align-items: center;
    background: var(--color-white);
    border: 1px solid var(--color-white);
    padding: 9px 10px;
    margin-bottom: 15px;
}

.container.preview {
    background: transparent;
    border: 0;
    border-bottom: 3px solid var(--color-gray-light-4);
    padding: 20px;
    margin-bottom: 0;
}

.content {
    flex: 1;
    color: var(--color-black);
    font-size: 16px;
    line-height: 18px;
}

.container.preview .content {
    color: var(--color-gray-dark);
}

.container.unconfirmed .content {
    color: var(--color-gray);
}

.name {
    font-weight: 700;
    margin: 0;
}

.container.preview .content .name {
    font-size: 21px;
    font-weight: 400;
    line-height: 24px;
    word-break: break-word;
}

.class {
    font-weight: 400;
    font-style: italic;
    margin: 0;
}

.buttonGroup {
    display: flex;
    padding-left: 10px;
    margin: 1px 0;
}

.button {
    padding-left: 10px;
}

.error {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    background: var(--color-error);
    color: var(--color-white);
    text-align: center;
    padding: 0 10px;
    z-index: 1;
}

.container.preview .error {
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    border-radius: 10px;
}

.error p {
    margin: 0;
}

@media (min-width: 1200px) {
    .container.large .content {
        font-size: 21px;
        line-height: 24px;
    }
}
