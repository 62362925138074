@media (min-width: 1200px) {
    .grid {
        display: flex;
        justify-content: center;
        margin: 0 -15px;
    }

    .main {
        min-width: 55.72519083%;
        max-width: 55.72519083%;
        width: 55.72519083%;
    }

    .aside {
        min-width: 44.27480917%;
        max-width: 44.27480917%;
        width: 44.27480917%;
    }

    .item {
        height: 100%;
        padding: 0 15px;
    }
}