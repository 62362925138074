.heading {
    text-align: center;
    margin-top: 40px;
}

.button {
    text-align: center;
    margin-top: 40px;
}

.button button {
    min-width: 230px;
}
