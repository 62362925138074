.container {
    position: relative;
    display: flex;
    border-bottom: 3px solid var(--color-gray-light-4);
    padding: 30px 20px;
}

.heading {
    min-width: 250px;
    max-width: 250px;
    width: 250px;
    color: var(--color-gray-dark);
}

.heading.disabled {
    color: var(--color-gray);
}

.name {
    font-size: 21px;
    line-height: 24px;
    word-break: break-word;
    margin: 0;
}

.class {
    font-size: 16px;
    font-style: italic;
    line-height: 18px;
    word-break: break-word;
    margin: 3px 0 0;
}

.buttonGroup {
    padding-top: 1px;
}

.buttonGroup button {
    margin-top: 15px;
}

.main {
    flex: 1;
    padding-left: 100px;
}

.error {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    background: var(--color-error);
    color: var(--color-white);
    text-align: center;
    border-radius: 10px;
    padding: 0 10px;
    z-index: 1;
}

.error p {
    margin: 0;
}