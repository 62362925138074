.button {
    display: flex;
    align-items: center;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 16px;
    border-radius: 12px;
    border: 0;
    box-shadow: var(--box-shadow-1);
    outline: 0;
    padding: 0;
    transition: background 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

.button:hover,
.button.loading {
    background: var(--color-primary);
    color: var(--color-white);
}

.text {
    padding: 0 10px 0 15px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: var(--color-white);
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    padding: 5px;
}

.icon svg {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 12px;
}
