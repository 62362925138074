.container {
    margin-bottom: 15px;
}

.wrapper {
    padding: 0 20px;
}

.button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 35px;
    background: rgb(46, 175, 190);
    background: -moz-linear-gradient(
        264deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: -webkit-linear-gradient(
        264deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: linear-gradient(
        264deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2eafbe",endColorstr="#2ac3bb",GradientType=1);
    color: var(--color-white);
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    border: 0;
    border-radius: 18px;
    box-shadow: var(--box-shadow-1);
    outline: 0;
    padding: 9px 20px 8px;
    cursor: pointer;
}

.button[disabled] {
    background: var(--color-gray);
    pointer-events: none;
}

.icon {
    display: flex;
    align-items: center;
    height: 18px;
}

.icon img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 18px;
}

.title {
    padding-left: 15px;
}

.indicator {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 10px;
    height: 12px;
    margin-top: -6px;
}

.indicator img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 12px;
}

.collapse {
    position: relative;
    height: 0;
    overflow: hidden;
    padding: 0 20px;
}