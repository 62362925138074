.field {
    margin-top: 25px;
}

.field.marginless {
    margin-top: 0;
}

.fieldRow {
    position: relative;
}

.label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: var(--color-gray);
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    line-height: 18px;
    padding: 0 20px;
    pointer-events: none;
    z-index: 2;
}

.input {
    display: block;
    width: 100%;
    height: 45px;
    background: var(--color-white);
    color: var(--color-gray-dark);
    font-size: 16px;
    text-align: left;
    line-height: 18px;
    border: 1px solid var(--color-white);
    border-radius: 23px;
    outline: 0;
    box-shadow: var(--box-shadow-2);
    padding: 13px 19px 12px;
}

.input[disabled] {
    background: var(--color-gray-light-2);
    pointer-events: none;
}

.input.masked {
    color: var(--color-white);
    pointer-events: none;
}

.maskTrigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 23px;
    cursor: pointer;
    z-index: 10;
}

.inputMask {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: var(--color-gray-dark);
    font-size: 16px;
    text-align: left;
    line-height: 30px;
    padding: 8px 18px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    pointer-events: none;
    z-index: 1;
}

.inputMark {
    position: relative;
    padding: 0 6px;
}

.inputMark.current:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #bfbebf;
    border-radius: 4px;
    animation: blink_animation 1.3s linear infinite;
}

.pointerMark {
    width: 2px;
    height: 30px;
    background: #bfbebf;
    border-radius: 2px;
    animation: blink_animation 1.3s linear infinite;
    margin-left: 4px;
}

@keyframes blink_animation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.metaError {
    color: var(--color-error);
    font-size: 11px;
    text-align: center;
    line-height: 12px;
    overflow: hidden;
}

.metaMessage {
    margin: 5px 0 0;
}
