.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 3000;
}

.modal:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-gray-light);
    opacity: 0.8;
    z-index: -1;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    padding: 20px 0;
}

.dialog {
    position: relative;
    width: 270px;
    background: var(--color-white);
    border: 1px solid var(--color-error);
    border-radius: 10px;
    color: var(--color-gray-dark);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 18px;
    box-shadow: var(--box-shadow-1);
    padding: 55px 15px 40px;
}

.dialog.colorPrimary {
    border-color: var(--color-primary);
}

.close {
    position: absolute;
    top: 14px;
    right: 14px;
    width: 30px;
    height: 30px;
    background: var(--color-error);
    border: 0;
    border-radius: 5px;
    box-shadow: none;
    outline: 0;
    padding: 0;
    cursor: pointer;
}

.close[disabled] {
    pointer-events: none;
}

.close:before,
.close:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border-bottom: 1px solid var(--color-white);
    width: 16px;
}

.close:before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.close:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.title {
    color: var(--color-error);
    font-size: 32px;
    line-height: 36px;
    margin: 0;
}

.title.colorPrimary {
    color: var(--color-primary);
}

.body {
    padding-top: 31px;
}

.modal.smallSpacing .body {
    padding-top: 17px;
}

.body p {
    margin: 0;
}

.body p + p {
    margin-top: 17px;
}

.footer {
    padding-top: 30px;
}

.modal.smallSpacing .footer {
    padding-top: 20px;
}

.button + .button {
    margin-top: 15px;
}

.button.white {
    border: 1px solid var(--color-primary);
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .8);
    border-radius: 10px;
}
