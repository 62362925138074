.container.bottom {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.container.bottom>div {
    flex: 1;
}

.openButton {
    position: relative;
    display: block;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    white-space: nowrap;
    border: 0;
    border-radius: 18px;
    box-shadow: var(--box-shadow-1);
    outline: 0;
    padding: 9px 50px 8px 20px;
    margin: 0 auto;
    cursor: pointer;
}

.openButton[disabled] {
    pointer-events: none;
}

.openButton:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 100%;
    background-color: var(--color-primary);
    background-image: url(./images/plus-icon.svg);
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 18px;
}

.field {
    position: relative;
}

.label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: var(--color-gray);
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    line-height: 18px;
    padding: 0 20px;
    pointer-events: none;
    z-index: 2;
}

.input {
    display: block;
    width: 100%;
    height: 45px;
    background: var(--color-white);
    color: var(--color-gray-dark);
    font-size: 16px;
    text-align: left;
    line-height: 18px;
    border: 1px solid var(--color-white);
    border-radius: 23px;
    outline: 0;
    box-shadow: var(--box-shadow-2);
    padding: 13px 19px 12px;
}

.input[disabled] {
    background: var(--color-gray-light-2);
    pointer-events: none;
}

.submitButton {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    background: var(--color-primary);
    border: 0;
    border-radius: 50%;
    box-shadow: none;
    outline: 0;
    overflow: hidden;
    padding: 0;
    cursor: pointer;
    z-index: 4;
}

.submitButton.disabled {
    pointer-events: none;
}

.submitIcon {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
}

.meta {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 700;
    line-height: 35px;
    white-space: nowrap;
    border-radius: 23px;
    padding: 0 15px;
    z-index: 3;
}