.select {
    max-width: 230px;
    margin: -20px auto 20px;
}

.filter {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.loading {
    display: flex;
    justify-content: center;
    margin: 0 -15px;
}

.robot {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 278px;
    margin-bottom: 25px;
    margin-left: auto;
}

@media (min-width: 1200px) {
    .utils {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    .select {
        min-width: 300px;
        margin: 0 40px 0 0;
    }

    .filter {
        margin: 0;
    }

    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: calc(25% - 30px);
        max-width: calc(25% - 30px);
        width: calc(25% - 30px);
        background: var(--color-white);
        height: 135px;
        border-radius: 10px;
        box-shadow: var(--box-shadow-1);
    }
}
