.field {
    margin-top: 25px;
}

.label {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    line-height: 15px;
    padding-left: 20px;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
}

.label a {
    color: inherit;
    font-weight: 600;
}

.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 5px;
    box-shadow: var(--box-shadow-1);
    overflow: hidden;
    transition: border 0.3s ease;
}

.checkbox.focused {
    border-color: var(--color-primary);
}

.checkbox.required {
    border-color: var(--color-error);
}

.icon svg {
    display: block;
}

.text {
    padding-left: 13px;
}

.input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
}

.metaError {
    color: var(--color-error);
    font-size: 11px;
    text-align: left;
    line-height: 12px;
    overflow: hidden;
    padding-left: 20px;
}

.metaMessage {
    margin: 5px 0 0;
}
