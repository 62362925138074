.messages {
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    text-align: center;
    margin-top: 23px;
}

.messages p {
    margin: 0;
}

.button {
    display: inline-block;
    background: transparent;
    color: var(--color-primary-dark);
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: uppercase;
    text-decoration: underline;
    border: 0;
    border-radius: 0;
    outline: 0;
    box-shadow: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.button:hover {
    text-decoration: none;
}