.container:not(.footerHidden) {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.item+.item {
    margin-top: 10px;
}

.footer {
    display: flex;
    align-items: center;
    padding: 30px 0 60px;
    margin-top: auto;
}

.robot {
    display: flex;
    align-items: flex-end;
    height: 162px;
}

.robot img {
    display: block;
    width: auto;
    height: auto;
    max-height: 162px;
}

.social {
    margin-left: auto;
    margin-right: -10px;
}

@media (min-width: 1200px) {
    .footer {
        justify-content: center;
        padding-bottom: 0;
    }
}
