.container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 35px;
    margin: 0 -5px;
}

.bubble {
    min-width: 50%;
    max-width: 50%;
    width: 50%;
    padding: 0 5px;
    margin-bottom: 25px;
}

.bubble.wide {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
}

@media (max-width: 319px) {
    .bubble {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .container {
        margin: 0 -20px;
        padding-bottom: 40px;
    }

    .bubble {
        padding: 0 20px;
        margin-bottom: 40px;
    }
}