.step2 {
    max-width: 630px;
    margin-inline: auto;
}

.buttons {
    display: flex;
    gap: 9px;
}

.buttons > * {
    flex: 1 0;
}

.select {
    margin-top: 20px;
}

.nextButton {
    width: fit-content;
    margin-inline: auto;
    margin-top: 50px;
    padding-bottom: 50px;
}

@media (min-width: 1200px) {
    .form {
        max-width: 435px;
        margin-inline: auto;
        padding: 50px 40px;
        border: 1px solid var(--color-primary);
        border-radius: 20px;
        margin-bottom: 50px;
    }
}
