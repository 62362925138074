.container {
    color: var(--color-primary);
    text-align: center;
    padding: 0 30px;
}

.title {
    font-weight: 400;
}

.counter {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
}

.title,
.counter {
    margin-bottom: 5px;
}

.progress {
    position: relative;
    height: 10px;
    border: 1px solid var(--color-primary);
    border-radius: 5px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgb(46, 175, 190);
    background: -moz-linear-gradient(
        90deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: linear-gradient(
        90deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2eafbe",endColorstr="#2ac3bb",GradientType=1);
    border-radius: 4px;
}

@media (min-width: 1200px) {
    .counter {
        font-size: 18px;
        line-height: 20px;
    }

    .title,
    .counter {
        margin-bottom: 6px;
    }

    .progress {
        height: 15px;
        border-radius: 4px;
    }

    .bar {
        border-radius: 2px;
    }
}
