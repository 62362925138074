.field {
    padding: 5px 0;
}

.fieldRow {
    position: relative;
    padding-right: 27px;
}

.label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: var(--color-gray);
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    line-height: 18px;
    padding: 0 20px;
    pointer-events: none;
    z-index: 2;
}

.input {
    display: block;
    width: 100%;
    height: 45px;
    background: var(--color-white);
    color: var(--color-gray-dark);
    font-size: 16px;
    text-align: left;
    line-height: 18px;
    border: 1px solid var(--color-white);
    border-radius: 23px 0 0 23px;
    outline: 0;
    box-shadow: var(--box-shadow-2);
    padding: 13px 46px 12px 19px;
}

.input[disabled] {
    background: var(--color-gray-light-2);
    pointer-events: none;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    right: 0;
    width: 55px;
    height: 55px;
    background: var(--color-primary);
    border: 0;
    border-radius: 50%;
    box-shadow: none;
    overflow: hidden;
    outline: 0;
    padding: 0;
    cursor: pointer;
    z-index: 1;
}

.button[disabled] {
    pointer-events: none;
}

.icon {
    position: relative;
    display: block;
    width: 19px;
    height: 19px;
}

.bar {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25px;
    border-bottom: 3px solid var(--color-white);
    border-radius: 2px;
}

.bar:first-child {
    transform: translate(-50%, -50%) rotate(45deg);
}

.bar:last-child {
    transform: translate(-50%, -50%) rotate(-45deg);
}
