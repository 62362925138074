.shapes {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 375px;
    height: 100%;
    transform: translateX(-50%);
    z-index: -10;
}

.shapes.isDesktop {
    max-width: none;
    margin-left: 0;
}

.shape {
    position: absolute;
    background-size: cover;
    background-position: center;
}

.shape.polygon {
    top: 28px;
    left: 107px;
    width: 478px;
    height: 459px;
    background-image: url(../../../assets/images/shapes/polygon-bg.svg);
}

.shapes.isDesktop .shape.polygon {
    top: -498px;
    left: 50%;
    width: 1097px;
    height: 1094px;
    background-image: url(../../../assets/images/shapes/polygon-bg_desktop.svg);
    margin-left: 524px;
    transform: translateX(-50%);
}

.shape.ellipse {
    top: 620px;
    left: -38px;
    width: 264px;
    height: 264px;
    background-image: url(../../../assets/images/shapes/ellipse-bg.svg);
}

.shapes.isDesktop .shape.ellipse {
    top: auto;
    bottom: -234px;
    left: 50%;
    width: 468px;
    height: 468px;
    margin-left: -615px;
    transform: translateX(-50%);
}