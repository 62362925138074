.grid {
    text-align: center;
    padding-bottom: 60px;
    margin-top: -30px;
}

.column {
    position: relative;
    background: var(--color-white);
    box-shadow: var(--box-shadow-1);
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;
}

.title {
    color: var(--color-primary);
    font-size: 21px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 14px;
}

.pope {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    z-index: 10;
}

.popeImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.error {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-error);
    color: var(--color-white);
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    border-radius: 10px;
    overflow: hidden;
    z-index: 10;
}

@media (min-width: 1200px) {
    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        padding-top: 22px;
        padding-bottom: 80px;
        margin-top: 0;
    }

    .column {
        border-radius: 20px;
        padding: 35px 20px 40px;
        margin-top: 0;
    }

    .title {
        margin: 0 0 20px;
    }

    .pope, .error {
        border-radius: 20px;
    }
}
