.competencyTest {
    position: relative;
    flex-grow: 1;
}

.piMobile {
    position: absolute;
    right: 20px;
    bottom: 0px;
    z-index: -1;
}

@media (min-width: 1200px) {
    .piMobile {
        display: none;
    }
}
