.container {
    margin-bottom: 10px;
}

.button {
    position: relative;
    display: block;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    line-height: 20px;
    word-break: break-word;
    box-shadow: var(--box-shadow-1);
    border-radius: 20px;
    padding: 10px 100px 10px 55px;
    cursor: pointer;
}

.button.disabled {
    pointer-events: none;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100%;
    background: rgb(46, 175, 190);
    background: -moz-linear-gradient(
        224deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: -webkit-linear-gradient(
        224deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: linear-gradient(
        224deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2eafbe",endColorstr="#2ac3bb",GradientType=1);
    border-radius: 20px;
    box-shadow: var(--box-shadow-2);
    overflow: hidden;
}

.icon img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 40px;
}

.difficulty {
    display: flex;
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -4px;
}
