.button {
    display: inline-block;
    position: relative;
    height: 40px;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    line-height: 38px;
    border: 1px solid var(--color-primary);
    border-radius: 20px;
    box-shadow: none;
    outline: 0;
    padding: 0 11px 0 41px;
    cursor: pointer;
}

.button.reversed {
    padding-left: 11px;
    padding-right: 41px;
}

.button.borderless {
    height: 30px;
    line-height: 30px;
    border: 0;
    padding: 0 0 0 40px;
}

.button.disabled {
    pointer-events: none;
}

.button.standalone {
    height: 30px;
    background: transparent;
    line-height: 30px;
    border: 0;
    box-shadow: none;
    padding: 0;
}

.arrow {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 30px;
    height: 30px;
}

.button.borderless .arrow {
    top: 0;
    left: 0;
}

.button.reversed .arrow {
    right: 4px;
    left: auto;
    rotate: 180deg;
}

.button.standalone .arrow {
    display: block;
    position: static;
}

.loader {
    position: absolute;
    top: 4px;
    left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: var(--color-primary);
    border-radius: 50%;
}

.button.borderless .loader {
    top: 0;
    left: 0;
}

.button.standalone .loader {
    position: static;
}
