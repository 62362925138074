@media (max-width: 1199px) {
    .element + .element {
        margin-top: 10px;
    }
}

@media (min-width: 1200px) {
    .container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 140px;
    }

    .container.center {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: ". a a .";
    }

    .container.center .element {
        grid-area: a;
    }
}
