.container {
    margin-bottom: 40px;
}

.loading {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
}

.loading p {
    margin: 0 0 20px;
}

@media (min-width: 1200px) {
    .container {
        margin-bottom: 50px;
    }
}