.container {
    margin-bottom: 30px;
}

.progress {
    position: relative;
    background: var(--color-gray-light-4);
    height: 10px;
    border-radius: 3px;
    overflow: hidden;
}

.bar {
    position: absolute;
    top: 0;
    height: 100%;
}

.bar.good {
    left: 0;
    background: var(--color-success);
    border-radius: 3px 0 0 3px;
}

.bar.bad {
    right: 0;
    background: var(--color-error);
    border-radius: 0 3px 3px 0;
}

.row {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
}

.block {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-gray);
    gap: 5px;
}

.block.good {
    color: var(--color-success);
}

.block.bad {
    color: var(--color-error);
}

.legend {
    width: 10px;
    height: 10px;
    background: var(--color-gray-light-4);
    border-radius: 3px;
}

.block.good .legend {
    background: var(--color-success);
}

.block.bad .legend {
    background: var(--color-error);
}

.title {
    color: var(--color-primary);
    font-size: 18px;
    text-align: center;
    line-height: 20px;
    margin-bottom: 10px;
}

@media (min-width: 1200px) {
    .progress {
        height: 25px;
        border-radius: 5px;
    }

    .bar.good {
        border-radius: 5px 0 0 5px;
    }
    
    .bar.bad {
        border-radius: 0 5px 5px 0;
    }

    .row {
        gap: 50px;
    }

    .block {
        font-size: 16px;
        line-height: 18px;
        gap: 10px;
    }

    .legend {
        width: 25px;
        height: 25px;
        border-radius: 5px;
    }
}