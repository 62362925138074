.body {
    position: relative;
}

.button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 0;
    border: 0;
    box-shadow: none;
    outline: 0;
    padding: 0;
    cursor: pointer;
}