.container {
    display: flex;
    flex-wrap: wrap;
    width: 124px;
}

.column {
    padding: 0 10px;
}

@media (max-width: 1199px) {
    .column {
        flex: 1;
        min-width: 50%;
        max-width: 50%;
        width: 50%;
    }

    .column:nth-child(n + 3) {
        margin-top: 20px;
    }
}

@media (min-width: 1200px) {
    .container {
        justify-content: center;
        width: auto;
    }
}
