.answerLink {
    text-decoration: none;
    color: var(--color-black);
    height: 40px;
    padding-inline: 20px;
    border-radius: 20px;
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.answerTitle {
    font-weight: 600;
}

.answerTitle.read {
    font-weight: revert;
}

.loader {
    margin-inline: auto;
    width: fit-content;
}
