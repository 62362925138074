.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: var(--color-white);
    z-index: 2000;
}

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100%;
    padding: 68px 0 40px;
}

.logo {
    padding-top: 40px;
    margin-top: auto;
    margin-bottom: auto;
}