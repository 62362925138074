.answerOnDemandForm {
    margin-bottom: 66px;
    max-width: 730px;
    width: 100%;
    margin-inline: auto;
}

.subtitle {
    display: none;
    font-size: 38px;
    line-height: 42px;
    color: var(--color-primary);
    text-align: center;
    margin-bottom: 50px;
}

.heading {
    max-height: 41px;
}

.heading * {
    font-size: 20px;
    line-height: 22px;
}

.flex {
    display: flex;
    gap: 30px;
}

.flex > * {
    flex: 1 0;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.textFields {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.searchInput {
    margin: 0;
}

.selectFields {
    display: flex;
    gap: 3px;
}

.selectFields > * {
    flex: 1 0;
}

.fileFields {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
}

.fileFieldsTitle {
    font-size: 24px;
    line-height: 27px;
    color: var(--color-primary);
    transition: color 250ms ease;
}

.fileFieldsTitle.disabled {
    color: var(--color-gray);
}

.fileFieldsInner {
    display: flex;
    gap: 30px;
}

.answerOnDemandSubscription {
    text-align: center;
    color: var(--color-primary);
    font-size: 18px;
    line-height: 20px;
    margin-top: -20px;
    margin-bottom: 15px;
    font-weight: 300;
}

@media (min-width: 1200px) {
    .subtitle {
        display: block;
    }

    .form {
        border-right: 1px solid var(--color-primary);
        padding-right: 30px;
    }

    .answerOnDemandSubscription {
        margin-top: -40px;
        font-size: 28px;
        line-height: 31px;
    }
}
