.button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 100px;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 18px;
    font-weight: 700;
    text-align: right;
    text-decoration: none;
    line-height: 20px;
    border: 1px solid var(--color-white);
    border-radius: 20px;
    box-shadow: var(--box-shadow-3);
    padding: 15px 14px 16px;
    margin-left: 15px;
    transition: border 0.2s linear;
    cursor: pointer;
}

.button.active,
.button:hover {
    border-color: var(--color-primary);
}

.button.stats {
    justify-content: center;
    text-align: center;
}

.button.disabled {
    pointer-events: none;
}

.button.gray {
    color: var(--color-gray-light-5);
}

.icon {
    position: absolute;
    top: -16px;
    left: -16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 50%;
    box-shadow: var(--box-shadow-3);
    padding: 4px;
    transition: border 0.2s linear;
    z-index: 4;
}

.button.active .icon,
.button:hover .icon {
    border-color: var(--color-primary);
}

.icon svg {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 40px;
}

.button.gray .icon svg {
    opacity: 0.3;
    filter:grayscale(1);
}

.fetching {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: var(--color-white);
    border-radius: 20px;
    z-index: 3;
}

.wrapper {
    width: 100%;
}

.name {
    display: block;
    margin-bottom: 6px;
}

.availableSoonBadge {
    position: absolute;
    top: 40px;
    left: -12px;
    width: 104px;
    height: 50px;
    z-index: 3;
}

.availableSoonBadge svg {
    display: block;
    width: auto;
    height: auto;
    max-height: 50px;
}

.error {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: var(--color-white);
    border-radius: 20px;
    z-index: 2;
}

@media (min-width: 1200px) {
    .button {
        min-height: 150px;
        font-size: 38px;
        line-height: 42px;
        border-width: 2px;
        padding: 26px 28px 30px;
        margin-left: 20px;
    }

    .icon {
        top: -20px;
        left: -20px;
        width: 100px;
        height: 100px;
        border-width: 2px;
        padding: 8px;
    }

    .icon svg {
        max-height: 80px;
    }

    .name {
        margin-bottom: 18px;
    }

    .availableSoonBadge {
        top: 67px;
        left: -19px;
        width: 162px;
        height: 80px;
    }

    .availableSoonBadge svg {
        max-width: 100%;
        max-height: 80px;
    }
}
