.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    background: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    outline: 0;
    box-shadow: none;
    padding: 4px;
    cursor: pointer;
}

.button[disabled] {
    pointer-events: none;
}

.button.important {
    background: var(--color-error);
    border-color: var(--color-error);
}

.button.blank {
    background: var(--color-white);
}

.button img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 24px;
}