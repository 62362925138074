.tooltip {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    margin-top: 10px;
    z-index: 20;
}

.container {
    position: relative;
    background: rgb(46, 175, 190);
    background: -moz-linear-gradient(
        236deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: -webkit-linear-gradient(
        236deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: linear-gradient(
        236deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2eafbe",endColorstr="#2ac3bb",GradientType=1);
    color: var(--color-white);
    font-size: 14px;
    text-align: center;
    line-height: 16px;
    border-radius: 10px;
    box-shadow: var(--box-shadow-1);
    padding: 21px 0 53px;
}

.container.single {
    padding-bottom: 27px;
}

.title {
    position: relative;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    padding: 0 56px;
    margin: 0 0 23px;
}

.title:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 9px;
    width: 39px;
    height: 39px;
    background-image: url(./images/hint-icon.svg);
    background-size: cover;
    background-position: center;
    transform: translateY(-50%);
    z-index: 1;
}

.close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    background-image: url(./images/close-icon.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding: 0;
    cursor: pointer;
    z-index: 2;
}

.body {
    padding: 0 10px;
}

.body p,
.body figure {
    margin: 0;
}

.body ul,
.body ol {
    text-align: center;
    padding-left: 15px;
    margin: 0;
}

.body p.blank {
    padding-bottom: 10px;
}

.body figure+p.blank {
    padding-bottom: 13px;
}

.body p.blank+figure {
    padding-top: 3px;
}

.body img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
}

.pagination {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 16px;
    left: 0;
    width: 100%;
    z-index: 2;
}

.bullet {
    width: 10px;
    height: 10px;
    border: 1px solid var(--color-white);
    border-radius: 50%;
    transition: background 0.3s ease;
    cursor: pointer;
}

.bullet + .bullet {
    margin-left: 5px;
}

.bullet.active {
    background: var(--color-white);
}

.arrows {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    padding: 0 10px;
    pointer-events: none;
    z-index: 3;
}

.arrow {
    width: 22px;
    height: 22px;
    background-color: transparent;
    background-size: cover;
    background-position: center;
    border: 0;
    border-radius: 0;
    padding: 0;
    pointer-events: auto;
    cursor: pointer;
}

.arrow[disabled] {
    pointer-events: none;
}

.arrow.prev {
    background-image: url(./images/swiper-arrow_prev.svg);
}

.arrow.next {
    background-image: url(./images/swiper-arrow_next.svg);
}

@media (min-width: 1200px) {
    .tooltip {
        left: 50%;
        width: 355px;
        margin-left: -177.5px;
    }
}