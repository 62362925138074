.logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 167px;
    height: 329px;

}

.robot {
    display: block;
    width: 100%;
    height: auto;
}

.circle {
    position: absolute;
    top: 45px;
    left: 60px;
    width: 53px;
    height: auto;
    animation: spin 1s linear infinite reverse;
    z-index: 1;
}

@keyframes spin {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: 360deg;
    }
}