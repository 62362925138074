.container {
    margin-bottom: 25px;
}

.field {
    position: relative;
}

.label {
    position: absolute;
    top: 15px;
    left: 20px;
    color: var(--color-gray);
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    z-index: 1;
    pointer-events: none;
}

.input {
    display: block;
    width: 100%;
    background: var(--color-white);
    color: var(--color-gray-dark);
    font-size: 16px;
    line-height: 18px;
    border: 1px solid var(--color-white);
    border-radius: 23px;
    box-shadow: var(--box-shadow-2);
    outline: 0;
    padding: 13px 20px 12px;
}