.answerOnDemand {
    max-width: 730px;
    width: 100%;
    margin-inline: auto;
    margin-bottom: 46px;
}

.addButton {
    color: var(--color-primary);
    font-size: 15px;
    line-height: 17px;
    font-weight: 700;
    text-align: center;
    border-bottom: 1px solid var(--color-primary);
    padding-bottom: 30px;
}

.addButton > a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: auto;
    cursor: pointer;
    padding: 0;
    width: 100px;
    height: 100px;
    background-color: var(--color-white);
    border: 0;
    border-radius: 12px;
    box-shadow: var(--box-shadow-2);
    margin-bottom: 5px;
}

.answerOnDemandSubscription {
    text-align: center;
    color: var(--color-primary);
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 15px;
    font-weight: 300;
    margin-top: -20px;
}

@media (min-width: 1200px) {
    .answerOnDemandSubscription {
        text-align: center;
        color: var(--color-primary);
        font-size: 28px;
        line-height: 31px;
        margin-bottom: 15px;
        font-weight: 300;
    }

    .alert {
        padding-top: 20px;
    }
}
