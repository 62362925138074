.container {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 56px;
    background: var(--color-error);
    color: var(--color-white);
    font-size: 14px;
    line-height: 16px;
    border-radius: 0 28px 28px 0;
    padding-left: 43px;
    padding-right: 28px;
    margin: 0 -5px 40px 23px;
}

.icon {
    position: absolute;
    top: 0;
    left: -28px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 56px;
    background: var(--color-white);
    border: 1px solid var(--color-error);
    border-radius: 28px;
    box-shadow: var(--box-shadow-1);
}

.content {
    padding: 12px 0;
}

.content p {
    margin: 0;
}
