.container {
    color: var(--color-gray-dark);
    font-weight: 400;
    text-align: center;
    margin-top: 20px;
}

.container p {
    margin: 8px 0 0;
}

.container a,
.container button {
    display: inline-block;
    background: transparent;
    color: var(--color-primary);
    text-decoration: underline;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding: 0;
    cursor: pointer;
}

.container a:hover,
.container button:hover {
    text-decoration: none;
}