.container {
    margin-bottom: 30px;
}

.editor {
    position: relative;
}

.editor:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    border-radius: 23px;
    transition: border 0.3s ease;
    pointer-events: none;
    z-index: 2;
}

.editor.success:before {
    border-color: #15b745;
}

.editor.error:before {
    border-color: #fe5a5e;
}

.editor.disabled {
    pointer-events: none;
}

.title {
    color: var(--color-black);
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    margin: -3px 0 10px;
}

.body {
    padding-top: 20px;
}

.answers {
    overflow: hidden;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: -10px;
    margin-right: -10px;
}

.column {
    padding-right: 10px;
    margin-top: 10px;
}

.answer {
    display: flex;
    align-items: center;
    height: 42px;
    background: var(--color-white);
    border: 1px solid var(--color-success);
    border-radius: 21px;
    padding: 0 15px;
}

.answer p {
    margin: 0;
}

.answer svg {
    display: block;
    width: auto;
    max-width: 100%;
    max-height: 24px;
}

@media (min-width: 1200px) {
    .container {
        max-width: 355px;
        margin-right: auto;
        margin-left: auto;
    }
}