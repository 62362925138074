.title {
    color: var(--color-primary);
    font-weight: 400;
    text-align: center;
    margin: 0;
}

.buttons {
    padding-top: 10px;
}

.button {
    max-width: 230px;
    margin: 10px auto 0;
}

.button > a,
.button > button {
    display: block;
    width: 100%;
}

.robot {
    display: block;
    width: 133px;
    height: 278px;
    margin-bottom: 25px;
    margin-left: auto;
}

.loading {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
}

.loading p {
    margin: 0 0 20px;
}

@media (min-width: 1200px) {
    .title {
        font-size: 38px;
        line-height: 42px;
        margin: 0 0 37px;
    }

    .tasks {
        background: var(--color-gray-light);
        border: 1px solid var(--color-primary);
        border-radius: 20px;
        padding: 30px 20px 20px;
        margin-bottom: 40px;
    }

    .grid {
        display: grid;
        grid-auto-columns: 33.3333%;
        grid-auto-flow: column;
        margin: 0 -40px;
    }

    .item {
        padding: 0 40px;
    }

    .buttons {
        display: flex;
        justify-content: center;
        padding-top: 0;
        margin: 0 -10px 50px;
    }

    .button {
        min-width: 250px;
        padding: 0 10px;
        margin: 0;
    }
}
