.progress {
    margin-bottom: 30px;
}

.list {
    margin-bottom: -10px;
}

@media (min-width: 1200px) {
    .progress {
        max-width: 660px;
        padding-top: 21px;
        margin: 0 auto 50px;
    }

    .list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 50px;
        row-gap: 40px;
        margin-bottom: 40px;
    }
}