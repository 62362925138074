.heading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
}

.icon svg {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 54px;
}

.title {
    color: var(--color-primary);
    margin: 0 0 0 20px;
}