.heading {
    margin-bottom: 20px;
}

.content {
    margin-bottom: -10px;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1199px) {
    .select {
        max-width: 230px;
        margin: 0 auto 20px;
    }

    .filter {
        text-align: right;
        margin-bottom: 20px;
    }
}

@media (min-width: 1200px) {
    .buttonGroup {
        position: relative;
        max-width: 370px;
        margin: 0 auto;
        z-index: 2;
    }

    .utils {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    .select {
        min-width: 300px;
        margin-right: 40px;
    }

    .content {
        background: var(--color-gray-light);
        border: 1px solid var(--color-primary);
        border-radius: 20px;
        padding: 50px 20px 40px;
        margin: -41px auto 50px;
    }

    .content.theory {
        width: 700px;
        background: var(--color-white);
        padding-bottom: 30px;
    }
    
    .loading {
        height: 180px;
    }

    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 68px;
        height: 68px;
        background: var(--color-white);
        border-radius: 50%;
        box-shadow: var(--box-shadow-1);
    }
}
