.container {
    text-align: center;
    padding-top: 4px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title {
    color: var(--color-primary);
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    margin: 0 0 20px;
}

.subtitle {
    color: var(--color-error);
    font-size: 12px;
    line-height: 16px;
    margin: -9px 0 20px;
}

.androidOrder {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    z-index: -9999;
}

apple-pay-button {
    --apple-pay-button-width: 230px;
    --apple-pay-button-height: 45px;
    --apple-pay-button-border-radius: 10px;
    --apple-pay-button-padding: 0px 0px;
    --apple-pay-button-box-sizing: border-box;
    box-shadow: var(--box-shadow-1);
    border-radius: 10px;
}

@media (min-width: 1200px) {
    .container {
        padding: 13px 0 10px;
    }

    .title {
        font-size: 32px;
        line-height: 36px;
        margin: 0 0 23px;
    }
}
