.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 50%;
    box-shadow: var(--box-shadow-1);
}

.container.bordered {
    border: 1px solid var(--color-gray-light);
    box-shadow: none;
}

.container svg {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 10px;
}

@media (min-width: 1200px) {
    .container.large {
        width: 50px;
        height: 50px;
    }

    .container.large svg {
        max-height: 25px;
    }
}
