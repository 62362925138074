.answerRequests {
    margin-bottom: 20px;
}

.filters {
    display: flex;
    gap: 11px;
    padding-top: 17px;
}

.filterButton {
    cursor: pointer;
    flex: 1 0;
    border: 0;
    padding: 0;
    background: var(--color-white);
    border-radius: 21px;
    box-shadow: var(--box-shadow-2);
    min-height: 41px;
    transition: box-shadow 250ms ease;
}

.filterButtonInner {
    display: flex;
    align-items: center;
    height: 100%;
    color: var(--color-primary);
    font-weight: 600;
}

.filterAmount {
    border-radius: 20px;
    box-shadow: var(--box-shadow-2);
    height: 100%;
    min-width: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 250ms ease;
}

.active {
    box-shadow: var(--box-shadow-2), 0 0 0 1px var(--color-primary);
}

.filterTitle {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-inline: auto;
}

.columnHeader {
    margin-bottom: 20px;
}

.answers {
    margin-top: 30px;
    display: flex;
    gap: 20px;
}

.answers.vertical {
    flex-direction: column;
    margin-top: unset;
}

.answers.vertical > .solved {
    border-top: 1px solid var(--color-primary);
    padding-top: 20px;
}

.answers > * {
    flex: 1 0;
}

.loader {
    margin-inline: auto;
    width: fit-content;
}

.empty {
    font-size: 24px;
    line-height: 27px;
    color: var(--color-primary);
    text-align: center;
}

.showMoreButton {
    display: block;
    margin-inline: auto;
}

@media (min-width: 1200px) {
    .answers {
        gap: 30px;
    }

    .answers.vertical > .solved {
        padding-top: 30px;
    }
}
