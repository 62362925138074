.ldsRing {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
}

.ldsRing.size18 {
    width: 18px;
    height: 18px;
}

.ldsRing.size24 {
    width: 24px;
    height: 24px;
}

.ldsRing span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    border: 4px solid var(--color-primary);
    border-radius: 50%;
    animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color-primary) transparent transparent transparent;
}

.ldsRing.size18 span {
    width: 18px;
    height: 18px;
    border-width: 2px;
}

.ldsRing.size24 span {
    width: 24px;
    height: 24px;
    border-width: 3px;
}

.ldsRing.red span {
    border-color: var(--color-error) transparent transparent transparent;
}

.ldsRing.white span {
    border-color: var(--color-white) transparent transparent transparent;
}

.ldsRing span:nth-child(1) {
    animation-delay: -0.45s;
}

.ldsRing span:nth-child(2) {
    animation-delay: -0.3s;
}

.ldsRing span:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes ldsRing {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
