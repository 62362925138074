.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 3000;
}

.dialog {
    position: relative;
    width: 100%;
    height: 100%;
    color: var(--color-gray-dark);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 18px;
}

.close {
    position: absolute;
    top: 26px;
    right: 26px;
    width: 30px;
    height: 30px;
    background: var(--color-error);
    border: 0;
    border-radius: 5px;
    box-shadow: none;
    outline: 0;
    padding: 0;
    cursor: pointer;
    z-index: 3;
}

.close:before,
.close:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border-bottom: 1px solid var(--color-white);
    width: 16px;
}

.close:before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.close:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.title {
    position: relative;
    color: var(--color-primary);
    font-size: 32px;
    line-height: 36px;
    padding-top: 23px;
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    cursor: default;
    z-index: 2;
}

.body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.whiteboardBg {
    opacity: 0.5;
    background: var(--color-white) url("../AnswerOpen/images/grid-bg.png")
        center / 10px 10px repeat;
    position: absolute;
    inset: 0;
}