.heading {
    text-align: center;
}

.button {
    display: block;
    width: 230px;
    margin-right: auto;
    margin-left: auto;
}

.refreshButton,
.forwardButton {
    margin-top: 40px;
}

.logoutButton {
    background: var(--color-error);
    margin-top: 15px;
}