.step3 {
    max-width: 630px;
    margin-inline: auto;
}

.label {
    margin-bottom: 20px;
}

.select {
    margin-bottom: 30px;
}

.nextButton {
    margin-inline: auto;
    width: fit-content;
    padding-bottom: 50px;
}

@media (min-width: 1200px) {
    .label {
        text-align: center;
    }

    .form {
        max-width: 435px;
        margin-inline: auto;
        padding: 25px 50px 10px;
        border: 1px solid var(--color-primary);
        border-radius: 20px;
        margin-bottom: 50px;
    }
}
