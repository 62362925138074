.container {
    position: absolute;
    top: -16px;
    right: -24px;
    width: 425px;
    background: var(--color-white);
    border-radius: 10px;
    box-shadow: var(--box-shadow-2);
    padding: 13px 0 10px;
    z-index: 1;
}

.container:before {
    content: '';
    position: absolute;
    bottom: 28px;
    left: 10px;
    width: 104px;
    height: 201px;
    background: url(images/background-robot.svg);
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.title {
    position: relative;
    color: var(--color-primary);
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    line-height: 28px;
    padding: 0 30px;
    margin: 0 0 15px;
}

.border {
    position: relative;
    border: 0;
    border-bottom: 2px solid var(--color-gray-light-4);
    margin: 0 10px;
    z-index: 2;
}

.border.bottom {
    margin-top: -2px;
}

.list {
    position: relative;
    height: 514px;
    overflow-y: auto;
    overflow-x: hidden;
    transition: height .2s ease;
}

.button {
    text-align: center;
    margin-top: 10px;
}

.readAll[disabled] {
    opacity: 0.5;
}

.empty {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    line-height: 20px;
    padding: 17px 20px 0;
}

@media (max-height: 659px) {
    .list {
        height: 362px;
    }
}

@media (max-height: 509px) {
    .list {
        height: 254px;
    }
}