.root {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
}

.stage {
    touch-action: none;
    position: absolute;
}

.toolbox {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 5px 10px;
    margin-top: auto;
    flex-grow: 1;
}

.clearButton,
.backspaceButton {
    position: relative;
    cursor: pointer;
    padding: 5px;
    background-color: var(--color-white);
    border: 0;
    border-radius: 18px;
    box-shadow: var(--box-shadow-1);
    font-weight: 500;
    font-size: 14px;
    max-width: 100px;
    flex-grow: 1;
}

.clearButton {
    color: var(--color-error);
}

.backspaceButton {
    color: var(--color-primary);
}

.clearButton > span,
.backspaceButton > span {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.backspaceButton {
    padding: 5px;
    background-color: var(--color-white);
    color: var(--color-primary);
    border: 0;
    border-radius: 18px;
    box-shadow: var(--box-shadow-1);
    font-weight: 500;
    font-size: 14px;
    max-width: 100px;
    flex-grow: 1;
}

.backspaceButton > span {
    display: flex;
    gap: 10px;
    align-items: center;
}

@media (min-width: 1200px) {
    .toolbox {
        padding: 20px 10px;
    }
}
