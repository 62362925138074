.container {
    color: var(--color-primary);
    text-align: center;
    margin-bottom: 20px;
}

.title {
    color: inherit;
    font-size: 21px;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}

.container.availableSoon .title {
    color: var(--color-gray-2);
}

.title.hasPopover {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 36px;
}

.subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    margin: 4px 0 0;
}

.container.availableSoon .subtitle {
    color: var(--color-gray)
}

.popover {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

@media (min-width: 1200px) {
    .container {
        margin-bottom: 52px;
    }

    .title {
        font-size: 38px;
        line-height: 42px;
    }

    .title.hasPopover {
        padding-left: 54px;
    }

    .subtitle {
        font-size: 18px;
        line-height: 20px;
        margin-top: 7px;
        padding-bottom: 4px;
    }

    .popover {
        margin-left: 20px;
    }
}
