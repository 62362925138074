.back {
    position: absolute;
    top: 10px;
    left: 10px;
    line-height: 0;
    z-index: 200;
}

.heading {
    text-align: center;
}

.form {
    padding-top: 15px;
}

.formButton {
    text-align: center;
    margin-top: 30px;
}

.formButton button {
    min-width: 235px;
}

.forwardButton {
    text-align: center;
    margin-top: 40px;
}

.forwardButton button {
    min-width: 230px;
}
