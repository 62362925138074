.button {
    position: relative;
    display: block;
    position: relative;
    background: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding: 0;
    cursor: pointer;
    z-index: 10;
}

.button[disabled] {
    pointer-events: none;
}

.icon {
    position: relative;
    display: block;
    width: 22px;
    height: 28px;
    overflow: hidden;
}

.bell {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.bell.default {
    top: 100%;
    background-image: url(../../../../assets/images/dashboard/bell-icon_default.svg);
}

.bell.important {
    background-image: url(../../../../assets/images/dashboard/bell-icon_important.svg);
}

.badge {
    position: absolute;
    top: -4px;
    left: 12px;
    min-width: 16px;
    height: 16px;
    background: var(--color-error);
    color: var(--color-white);
    font-size: 12px;
    text-align: center;
    line-height: 16px;
    border-radius: 8px;
    padding: 0 3px 0 4px;
}