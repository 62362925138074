.tasks {
    display: flex;
    flex-direction: column;
    margin-bottom: -10px;
}

.empty {
    background: var(--color-gray-light-2);
    color: var(--color-black);
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    line-height: 20px;
    box-shadow: var(--box-shadow-1);
    border-radius: 20px;
    padding: 10px 20px;
}

@media (min-width: 1200px) {
    .tasks {
        margin-bottom: 20px;
    }

    .tasksGrid {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .empty {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 30px);
        height: 135px;
        font-size: 24px;
        line-height: 27px;
        border-radius: 10px;
        margin: 0 15px;
    }
}
