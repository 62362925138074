.root {
    flex-grow: 1;
    padding-bottom: 46px;
    display: flex;
    flex-direction: column;
}

.subtitle {
    display: none;
    text-align: center;
    font-size: 38px;
    line-height: 42px;
    color: var(--color-primary);
    margin-bottom: 30px;
}

.description {
    text-align: center;
}

.notifications {
    max-width: 700px;
    width: 100%;
    margin-inline: auto;
}

.buttonAdd {
    padding-block: 17px;
    border-bottom: 1px var(--color-primary-2) solid;
    display: flex;
    justify-content: center;
}

.buttonAdd > button {
    cursor: pointer;
    display: block;
    padding: 0;
    border: 0;
    border-radius: 50%;
    background-color: var(--color-primary);
    padding: 15px;
    box-shadow: var(--box-shadow-2);
}

.buttonAdd > button > img {
    display: block;
    transform: scale(1.8);
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 20px;
}

.buttonDecline {
    border: 1px var(--color-primary) solid;
    color: var(--color-primary);
    background-color: var(--color-white);
}

.piMobile {
    display: block;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 5px;
}

@media (min-width: 1200px) {
    .subtitle {
        display: block;
    }

    .notifications {
        margin-top: 30px;
    }

    .piMobile {
        display: none;
    }
}
