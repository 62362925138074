.container {
    position: relative;
    background: var(--color-white);
    padding: 20px 0;
    margin-bottom: 60px;
    z-index: 1001;
}

.collapsed {
    overflow-y: hidden;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1340px;
    padding: 0 30px;
    margin: 0 auto;
}

.column.left,
.column.right {
    flex: 2;
}

.column.center {
    flex: 1;
}

.menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu>li+li {
    margin-left: 40px;
}

.notifications {
    padding-top: 4px;
}