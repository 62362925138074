.field {
    margin-top: 25px;
}

.fieldRow {
    position: relative;
}

.label {
    position: absolute;
    top: 14px;
    left: 0;
    width: 100%;
    color: var(--color-gray);
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    line-height: 18px;
    padding: 0 20px;
    pointer-events: none;
    z-index: 2;
}

.input {
    display: block;
    width: 100%;
    height: 45px;
    background: var(--color-white);
    color: var(--color-white);
    caret-color: var(--color-gray-dark);
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    line-height: 18px;
    border: 1px solid var(--color-white);
    border-radius: 23px;
    outline: 0;
    box-shadow: var(--box-shadow-2);
    padding: 13px 19px 12px;
}

.input[disabled] {
    background: var(--color-gray-light-2);
    pointer-events: none;
}

.placeholder {
    position: absolute;
    top: 1px;
    left: 0;
    width: 100%;
    height: 44px;
    color: var(--color-gray-dark);
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    line-height: 18px;
    overflow: hidden;
    padding: 13px 0;
    pointer-events: none;
}

.placeholderValue {
    position: absolute;
    left: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 20px;
    margin: 0;
}

.loader {
    position: absolute;
    top: 19px;
    right: 28px;
}

.dots {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--color-primary);
    box-shadow: 10px 0 var(--color-primary), -10px 0 var(--color-primary);
    position: relative;
    animation: flash 0.3s ease-out infinite alternate;
}

@keyframes flash {
    0% {
        background-color: var(--color-gray);
        box-shadow: 12px 0 var(--color-gray), -12px 0 var(--color-primary);
    }
    50% {
        background-color: var(--color-primary);
        box-shadow: 12px 0 var(--color-gray), -12px 0 var(--color-gray);
    }
    100% {
        background-color: var(--color-gray);
        box-shadow: 12px 0 var(--color-primary), -12px 0 var(--color-gray);
    }
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 184px;
    background: var(--color-white);
    border: 1px solid var(--color-primary);
    border-top: 0;
    border-radius: 0 0 18px 18px;
    box-shadow: var(--box-shadow-1);
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 100;
}

.dropdown.hidden {
    overflow-y: hidden;
}

.menu {
    list-style: none;
    padding: 8px 0;
    margin: 0;
}

.menuButton {
    display: block;
    width: 100%;
    background: transparent;
    color: var(--color-gray);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 18px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding: 5px 20px;
    transition: background 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

.menuButton.active,
.menuButton:hover {
    background: var(--color-primary);
    color: var(--color-white);
}

.menuButton[disabled] {
    pointer-events: none;
}

.menuMessage {
    display: block;
    font-weight: 400;
    padding: 5px 20px;
}

.menuMessage.error {
    color: var(--color-error);
}

.menuMessageImportant {
    display: inline-block;
    font-weight: 600;
    word-break:break-all;
}