.container {
    padding-bottom: 60px;
}

.main {
    padding-top: 35px;
}

.frameContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 810px;
    margin: 0 auto;
    justify-content: space-between;
    margin-bottom: 40px;
}

.frame {
    position: relative;
    background-color: var(--color-white);
    border: 1px solid var(--color-primary);
    border-radius: 17px;
    margin-top: 30px;
    padding: 10px;
    flex-basis: 360px;
}

.frameIcon {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-40%, -40%);
    background-color: var(--color-white);
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    background-position: center;
    background-size: 65%;
    background-repeat: no-repeat;
    width: 60px;
    height: 56px;
}

.frameTitle {
    text-align: center;
    text-transform: uppercase;
    margin: 5px;
}

.frameSubTitle {
    text-align: center;
    margin: 0px;
    color: var(--color-primary);
    font-size: 18PX;
}

.frameTitle span {
    color: var(--color-primary);
}

.list {
    margin: 0;
    font-size: 14px;
}

.list span {
    font-weight: bold;
}

.info {
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    padding: 0 2px;
}


.block {
    margin-bottom: 35px;
}

.listContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
}

.line {
    width: 100%;
    background-color: var(--color-primary);
    height: 2px;
    margin-bottom: 10px;
}

.select {
    padding-top: 20px;
}

.buttons {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 10px;
}

.button {
    text-align: center;
    margin-top: 20px;
}

.robot {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 184px;
    margin-top: 30px;
}

.robot img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 184px;
}

.askTitle {
    color: var(--color-primary);
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    margin: -5px 0 0;
}

.askButton {
    margin-top: 26px;
}

.buttonContainer {
    max-width: 810px;
    margin: 40px auto;
    display: flex;
    justify-content: flex-end;
    max-height: 35px;
}

.buttonContainer button {
    height: 30px;
    padding: 5px 15px;
    font-size: 14px;
    letter-spacing: 1px;
}

.link {
    text-decoration: none;
    color: var(--color-gray-dark);
}


@media (min-width: 1200px) {
    .container {
        padding-bottom: 80px;
    }

    .main {
        max-width: 810px;
        margin: 0 auto;
    }

    .block {
        margin-bottom: 72px;
    }

    .list {
        flex-basis: 50%;
        font-size: 16px;
    }

    .line {
        margin-bottom: 20px;
    }

    .info {

        font-size: 14px;

    }

    .select {
        padding-top: 40px;
    }

    .buttons {
        flex-direction: row;
        justify-content: center;
        margin: 60px -20px 0;
    }

    .button {
        padding: 0 20px;
        margin-top: 0;
    }

    .askTitle {
        font-size: 32px;
        font-weight: 700;
        line-height: 36px;
        margin: 0;
    }

    .askButton {
        margin-top: 33px;
    }

    .list li {
        margin-bottom: 3px;
    }
}