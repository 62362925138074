.stepperWithTitle {
}

.title {
    font-size: 24px;
    line-height: 27px;
}

.stepper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 30px;
}

.step {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-basis: 133px;
    text-align: center;
    font-weight: 700;
}
