.container {
    position: relative;
    background: var(--color-primary-light);
    color: var(--color-white);
    text-align: right;
    padding: 18px 30px;
    margin-top: 30px;
}

.container:before,
.container:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 1440px;
    background: var(--color-primary-light);
    z-index: -2;
}

.container:before {
    right: 100%;
    border-radius: 10px 0 0 10px;
}

.container:after {
    left: 100%;
    border-radius: 0 10px 10px 0;
}

.discount {
    font-size: 16px;
    line-height: 18px;
    overflow: hidden;
}

.price {
    font-size: 21px;
    font-weight: 600;
    line-height: 24px;
}

.robot {
    position: absolute;
    bottom: 100%;
    left: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 300px;
    height: 400px;
    margin-left: -215px;
    margin-bottom: 36px;
    transition: margin 0.3s ease;
    z-index: -1;
}

.robot img {
    width: auto;
    max-width: 300px;
    height: auto;
    max-height: 400px;
}

@media (min-width: 1200px) {
    .container {
        padding: 38px 38px 37px;
        margin-top: 60px;
    }

    .wrapper {
        max-width: 700px;
        margin: 0 auto;
    }

    .discount {
        font-size: 29px;
        line-height: 32px;
    }

    .price {
        font-size: 38px;
        line-height: 42px;
    }
}

@media (min-width: 1700px) {
    .robot {
        margin-left: -45px;
    }
}
