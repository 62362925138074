.container {
    border-bottom: 1px solid rgba(46, 175, 190, 0.4);
    padding-bottom: 15px;
}

.heading {
    padding: 0 10px;
    margin-bottom: 20px;
}

.heading h2 {
    color: var(--color-gray-dark);
    font-size: 21px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 24px;
    margin: 1px 0 0;
}

.heading h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    margin: 0;
}

.thumbnail {
    position: relative;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    box-shadow: var(--box-shadow-2);
    padding-bottom: 48%;
    overflow: hidden;
    z-index: 1;
}

.caption {
    padding: 0 10px;
    margin-top: 12px;
}

.content {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

.content p {
    margin: 0;
}

.button {
    margin-top: 11px;
}
