.container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 50px;
    margin: 0 -5px;
}

.item {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    padding: 0 5px;
    margin-bottom: 10px;
}

.link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    line-height: 20px;
    border: 1px solid var(--color-white);
    border-radius: 20px;
    box-shadow: var(--box-shadow-1);
    padding: 10px 15px 10px;
    transition: border 0.3s linear;
}

.link:hover {
    border-color: var(--color-primary);
}

@media (min-width: 319px) {
    .item {
        min-width: 50%;
        max-width: 50%;
        width: 50%;
    }
}

@media (min-width: 1200px) {
    .container {
        padding-bottom: 0;
        margin-bottom: -10px;
    }

    .link {
        height: 60px;
        border-radius: 10px;
    }
}