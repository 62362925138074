.defaultTextarea {
    border: 0;
    border-radius: 23px;
    box-shadow: var(--box-shadow-2);
    background-color: var(--color-white);
    padding: 14px 20px;
    resize: none;
    min-width: 100%;
    min-height: 160px;
    outline: none;
    transition: background-color 250ms ease;
}

.defaultTextarea[disabled] {
    background-color: var(--color-gray-light-2);
    pointer-events: none;
}
