.container {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.container.task {
    overflow-y: scroll;
}

.wrapper {
    display: flex;
    flex-direction: column;
    max-width: 375px;
    min-height: calc(100% - 91px);
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1200px) {
    .wrapper {
        max-width: 1340px;
        min-height: calc(100% - 150px);
        padding-right: 30px;
        padding-left: 30px;
    }
}
