/* FONTS */
@font-face {
    font-family: "Glory";
    src: url(../fonts/Glory/Glory-Thin.ttf);
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Glory";
    src: url(../fonts/Glory/Glory-ThinItalic.ttf);
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "Glory";
    src: url(../fonts/Glory/Glory-Light.ttf);
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Glory";
    src: url(../fonts/Glory/Glory-LightItalic.ttf);
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Glory";
    src: url(../fonts/Glory/Glory-Regular.ttf);
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Glory";
    src: url(../fonts/Glory/Glory-Italic.ttf);
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Glory";
    src: url(../fonts/Glory/Glory-Medium.ttf);
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Glory";
    src: url(../fonts/Glory/Glory-MediumItalic.ttf);
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Glory";
    src: url(../fonts/Glory/Glory-SemiBold.ttf);
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Glory";
    src: url(../fonts/Glory/Glory-SemiBoldItalic.ttf);
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Glory";
    src: url(../fonts/Glory/Glory-Bold.ttf);
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Glory";
    src: url(../fonts/Glory/Glory-BoldItalic.ttf);
    font-weight: 700;
    font-style: italic;
}

/* COLORS */
:root {
    --color-white: #ffffff;
    --color-black: #000000;
    --color-error: #fe5a5e;
    --color-warning: #ff9a01;
    --color-success: #15b745;
    --color-gray: #879195;
    --color-gray-2: #707070;
    --color-gray-3: #f6f7f9;
    --color-gray-light: #eef0f3;
    --color-gray-light-2: #e5e7ea;
    --color-gray-light-3: #f2f2f2;
    --color-gray-light-4: #d6dee1;
    --color-gray-light-5: #dee1e1;
    --color-gray-dark: #374247;
    --color-primary: #2eafbe;
    --color-primary-2: #2db3be;
    --color-primary-light: #2ac3bb;
    --color-primary-light-2: #abd9e0;
    --color-primary-dark: #087e8b;
    --box-shadow-1: 0 3px 6px rgba(0, 0, 0, 0.2);
    --box-shadow-2: 0 3px 15px rgba(0, 0, 0, 0.2);
    --box-shadow-3: 3px 3px 20px rgba(0, 0, 0, 0.2);
}

/* GLOBAL */
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html,
body,
#root {
    position: relative;
    height: 100%;
    overflow: hidden;
}

body {
    background-color: var(--color-white);
    font-family: "Glory", "Arial", sans-serif;
    color: var(--color-gray-dark);
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

button {
    outline: 0;
}

button:disabled {
    cursor: default;
}

button:-moz-focusring {
    outline: 0 !important;
}

h1 {
    font-size: 38px;
    line-height: 42px;
}

h2 {
    font-size: 32px;
    line-height: 36px;
}

h3,
h4,
h5,
h6 {
    font-size: 24px;
    line-height: 27px;
}

b,
strong {
    font-weight: 700;
}

input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}
