.container {
    text-align: center;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 12px;
}

.icon svg {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 12px;
}

.text {
    padding-left: 10px;
}
