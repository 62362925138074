.field {
    margin-top: 25px;
}

.title {
    color: var(--color-gray-dark);
    font-size: 16px;
    text-align: center;
    line-height: 18px;
    margin: 0 0 5px;
    transition: color .3s ease;
}

.title.success {
    color: var(--color-primary);
}

.title.error {
    color: var(--color-error);
}

.title.disabled {
    color: var(--color-gray-dark);
}

.fieldRow {
    display: flex;
    margin: 0 -2px;
}

.fieldColumn {
    flex: 1;
    padding: 0 2px;
}

.fieldColumn.large {
    flex: 2;
}

.metaError {
    color: var(--color-error);
    font-size: 11px;
    font-weight: 400;
    text-align: center;
    line-height: 12px;
    overflow: hidden;
}

.metaMessage {
    margin: 5px 0 0;
}