.container {
    display: flex;
    justify-content: center;
    margin: 0 -16px;
}

.item {
    padding: 0 16px;
}

.button {
    display: block;
    width: 181px;
    height: 52px;
    background: var(--color-black);
    border-radius: 10px;
    overflow: hidden;
    transition: opacity 0.3s ease;
    cursor: pointer;
}

.button:hover {
    opacity: 0.7;
}

.button img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 52px;
}
