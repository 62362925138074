.container {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 21px 0 25px;
}

.item {
    padding: 0 30px;
}
