.examTasks {
    position: relative;
    margin-bottom: 46px;
    flex-grow: 1;
}

.infinity {
    display: none;
    position: absolute;
    right: clamp(-180px, (100vw - 1360px) / (-2), 0px);
    bottom: 30px;
    height: 466px;
    z-index: -1;
}

.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 20px;
}

.title {
    font-size: 24px;
    line-height: 27px;
    color: var(--color-primary);
    max-width: 200px;
}

.titleDesktop {
    color: var(--color-primary);
    font-size: 38px;
    line-height: 42px;
    text-align: center;
    padding-bottom: 38px;
    display: none;
}

.controls {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select {
    min-width: 108px;
}

.exams {
    margin-top: 20px;
}

.button {
    display: flex;
    align-items: center;
}

.loadingMask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

@media (min-width: 1200px) {
    .infinity {
        display: unset;
    }

    .heading {
        display: none;
    }

    .titleDesktop {
        display: block;
    }

    .main {
        background-color: var(--color-gray-light);
        border-radius: 20px;
        border: 1px var(--color-primary) solid;
        padding: 10px 20px 30px;
        margin-bottom: 46px;
    }

    .exams {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-flow: column;
        column-gap: 87px;
    }
}
