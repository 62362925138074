.container {
    padding: 4px 0 31px;
}

.wrapper {
    display: flex;
    justify-content: space-between;
}

.info {
    display: flex;
    align-items: center;
}

.text {
    color: var(--color-primary);
    font-size: 14px;
    line-height: 16px;
    padding-left: 10px;
}

.status {
    margin-left: auto;
    padding-left: 10px;
    padding-bottom: 14px;
}

.sheets {
    max-width: 75%;
    font-size: 14px;
    text-align: right;
    line-height: 16px;
    padding: 3px 0;
    margin-left: auto;
}

.sheets span {
    display: inline-block;
}

@media (min-width: 1200px) {
    .container {
        padding-top: 0;
        margin-top: -6px;
    }

    .container.large {
        padding-bottom: 37px;
        margin-top: 0;
    }

    .container.large .text {
        font-size: 18px;
        line-height: 20px;
        padding-left: 20px;
    }

    .container.large .status {
        display: flex;
        align-items: center;
        padding-bottom: 16px;
    }

    .sheets.large {
        font-size: 16px;
        line-height: 18px;
        padding: 12px 0 0;
        margin-bottom: -4px;
    }
}
