.container {
    margin-bottom: 10px;
}

.button {
    position: relative;
    display: block;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    line-height: 20px;
    box-shadow: var(--box-shadow-1);
    border-radius: 20px;
    padding: 10px 60px 10px 55px;
    cursor: pointer;
}

.button.disabled {
    pointer-events: none;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100%;
    background: rgb(46, 175, 190);
    background: -moz-linear-gradient(
        224deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: -webkit-linear-gradient(
        224deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: linear-gradient(
        224deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2eafbe",endColorstr="#2ac3bb",GradientType=1);
    border-radius: 20px;
    box-shadow: var(--box-shadow-2);
    overflow: hidden;
}

.icon img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 40px;
}

.progress {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 35px;
    background: var(--color-primary);
    color: var(--color-white);
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    line-height: 14px;
    border-radius: 10px;
    padding: 1px 0;
    margin-top: -8px;
}

.progress.low {
    background: var(--color-error);
}

.progress.medium {
    background: var(--color-warning);
}

.progress.high {
    background: var(--color-success);
}

@media (min-width: 1200px) {
    .container {
        min-width: calc(25% - 30px);
        max-width: calc(25% - 30px);
        width: calc(25% - 30px);
        margin: 0 15px 30px;
    }

    .button {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        min-height: 135px;
        font-size: 24px;
        text-align: right;
        line-height: 27px;
        border-radius: 10px;
        padding: 98px 10px 10px 10px;
    }

    .icon {
        top: 10px;
        left: 10px;
        width: 78px;
        height: 78px;
        border-radius: 50%;
    }

    .icon img {
        max-height: 78px;
    }

    .progress {
        top: 10px;
        right: 10px;
        width: 50px;
        font-size: 16px;
        line-height: 18px;
        margin-top: 0;
    }
}
