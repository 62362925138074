.container {
    position: relative;
    padding: 0 10px;
    transition: background .3s ease;
    z-index: 1;
}

.container:hover,
.container.disabled {
    background: rgba(214, 222, 225, .2);
}

.container.disabled {
    pointer-events: none;
}

.wrapper {
    display: flex;
    align-items: center;
    border-bottom: 2px solid var(--color-gray-light-4);
    padding: 9px 0 8px;
}

.icon {
    display: flex;
    align-items: center;
    max-width: 32px;
    min-width: 32px;
    width: 32px;
    height: 32px;
    margin-bottom: 2px;
}

.icon img,
.icon svg {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 32px;
}

.body {
    flex: 1;
    padding-left: 10px;
}

.text {
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    margin: 0 0 7px;
}

.container.critical .text {
    color: var(--color-error);
    font-size: 16px;
    line-height: 18px;
}

.actions {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 -5px;
}

.actions > li {
    padding: 0 5px;
}
