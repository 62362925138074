.logoHolder {
    display: flex;
    align-items: center;
    width: 120px;
    height: 50px;
}

.logoHolder.isSmall {
    width: 100px;
}

.logoHolder.isCenter {
    margin-right: auto;
    margin-left: auto;
}

.logoImage {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 50px;
}