.container {
    margin-bottom: 40px;
}

.button {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(100% - 18px);
    min-height: 56px;
    background: var(--color-primary);
    color: var(--color-white);
    font-size: 14px;
    text-decoration: none;
    line-height: 16px;
    border: 0;
    border-radius: 0 28px 28px 0;
    box-shadow: none;
    outline: 0;
    padding-left: 43px;
    padding-right: 28px;
    margin-left: 23px;
    margin-right: -5px;
    cursor: pointer;
}

.button.important {
    background: var(--color-error);
}

.button.disabled {
    pointer-events: none;
}

.icon {
    position: absolute;
    top: 0;
    left: -28px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 56px;
    background: var(--color-white);
    border: 1px solid var(--color-primary);
    border-radius: 28px;
    box-shadow: var(--box-shadow-1);
}

.button.important .icon {
    border-color: var(--color-error);
}

.text {
    padding: 12px 0;
}
