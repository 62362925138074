.button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    line-height: 19px;
    border: 1px solid var(--color-primary);
    border-radius: 20px;
    padding: 10px 15px;
    cursor: pointer;
}

.button[disabled] {
    pointer-events: none;
}

.icon {
    display: flex;
    align-items: center;
    height: 17px;
    margin-left: 10px;
}

.icon svg {
    width: auto;
    max-width: 24px;
    height: auto;
    max-height: 17px;
}

@media (min-width: 1200px) {
    .button {
        min-width: 165px;
        font-size: 24px;
        font-weight: 400;
        line-height: 26px;
        border-radius: 28px;
        padding: 14px 20px;
    }

    .icon {
        height: 20px;
        margin-left: 12px;
    }

    .icon svg {
        max-width: 28px;
        max-height: 20px;
    }
}