.container {
    position: relative;
    margin-bottom: 40px;
    z-index: 1003;
}

.collapse {
    position: relative;
    height: 5px;
    background: var(--color-white);
    overflow: hidden;
    z-index: 1;
}

.collapsed {
    overflow-y: hidden;
}

.menu {
    display: flex;
    justify-content: space-between;
    max-width: 375px;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}

.menuBlock {
    flex: 2;
    line-height: 0;
}

.menuBlock.center {
    flex: 1;
    min-width: 92px;
    max-width: 92px;
    width: 92px;
}

.menuBlock.right {
    text-align: right;
}
