.container {
    margin-top: -25px;
}

.form {
    text-align: center;
}

.formButton {
    margin-top: 30px;
}

.cancelButton {
    margin-top: 25px;
}

.cancelButton a {
    background: var(--color-error);
}

@media (max-width: 1199px) {
    .container {
        padding-bottom: 60px;
    }
}