.answerOnDemandDetails {
    margin-bottom: 66px;
    border-radius: 20px;
    margin-inline: auto;
    max-width: 700px;
    width: 100%;
}

.heading {
    height: 41px;
    margin-bottom: 25px;
}

.heading * {
    font-size: 20px;
    line-height: 22px;
}

.content {
    margin-bottom: 40px;
}

.date {
    text-align: right;
    padding-bottom: 25px;
    color: var(--color-black);
}

.form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.textFields {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.selectFields {
    display: flex;
    gap: 3px;
}

.selectFields > * {
    flex: 1 0;
}

.fileFields {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
}

.fileFieldsTitle {
    font-size: 24px;
    line-height: 27px;
    color: var(--color-primary);
    transition: color 250ms ease;
}

.fileFieldsTitle.disabled {
    color: var(--color-gray);
}

.fileFieldsInner {
    display: flex;
    gap: 30px;
}

@media (min-width: 1200px) {
    .answerOnDemandDetails {
        background-color: var(--color-white);
    }

    .subtitle {
        display: block;
    }

    .content {
        padding: 20px;
    }

    .date {
        padding-bottom: 40px;
    }

    .form {
        padding: 20px;
    }
}
