.button {
    display: flex;
    align-items: center;
    background: transparent;
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 20px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
}

.button:hover {
    opacity: 0.7;
}

.button[disabled] {
    pointer-events: none;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin-left: 6px;
}

.icon img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 18px;
}
