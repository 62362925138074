.body {
    position: relative;
    max-width: 375px;
    height: calc(100dvh - 63px);
    padding-top: 20px;
    margin: 0 auto;
    z-index: 1;
}

.body.fallback {
    height: calc(100vh - 63px);
}

.body:before {
    content: "";
    position: absolute;
    bottom: 27px;
    left: 8px;
    width: 104px;
    height: 201px;
    background-image: url(images/background-robot.svg);
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.heading {
    position: relative;
    text-align: center;
    padding: 0 60px;
}

.title {
    color: var(--color-primary);
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    margin: 0;
}

.toggle {
    position: absolute;
    top: 50%;
    right: 25px;
    margin-top: -14px;
}

.border {
    position: relative;
    border: 0;
    border-bottom: 2px solid var(--color-gray-light-4);
    margin: 0 10px;
    z-index: 2;
}

.border.top {
    margin-top: 36px;
    margin-bottom: -2px;
}

.border.bottom {
    margin-top: -2px;
}

.container {
    position: relative;
    height: calc(100dvh - 163px);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
}

.body.fallback .container {
    height: calc(100vh - 163px);
}

.wrapper {
    border-width: 1px 0;
    border-style: solid;
    border-color: var(--color-gray-light-4) transparent;
    margin: 0 10px;
}

.list {
    margin: 0 -10px;
}

.empty {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background: var(--color-white);
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    line-height: 20px;
    padding: 17px 15px;
    z-index: 1;
}

.empty:after {
    content: '';
    position: absolute;
    right: 10px;
    bottom: 0;
    left: 10px;
    border-bottom: 2px solid var(--color-gray-light-4);
}