.container {
    border-top: 1px solid rgba(46, 175, 190, 0.35);
    padding-top: 20px;
    margin-top: 20px;
}

.container.preview {
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
    margin-right: -10px;
    margin-left: -10px;
}

.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 46px;
    background: var(--color-gray-light-2);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    border: 1px solid var(--color-gray-light-4);
    padding: 9px 10px;
    margin-bottom: 15px;
}