.container {
    max-width: 335px;
    margin: 0 auto;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.cancelButton {
    margin-top: 15px;
}

.cancelButton button {
    background: var(--color-error);
}

.select {
    margin-bottom: 25px;
}
