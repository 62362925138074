.button {
    position: relative;
    display: inline-block;
    background: transparent;
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    line-height: 18px;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    outline: 0;
    padding: 1px 0 1px 30px;
    cursor: pointer;
}

.button[disabled] {
    pointer-events: none;
}

.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: var(--color-white);
    border-radius: 4px;
    box-shadow: var(--box-shadow-1);
    overflow: hidden;
    z-index: 1;
}

.content {
    display: flex;
    align-items: center;
}

.icon {
    display: flex;
    align-items: center;
    height: 16px;
    margin-right: 5px;
}

.icon svg {
    width: auto;
    max-width: 16px;
    height: auto;
    max-height: 10px;
}

.name {
    word-break: break-word;
}
