.container {
    display: flex;
    align-items: center;
    color: var(--color-primary);
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    line-height: 14px;
}

.container.availableSoon {
    color: var(--color-gray-light-5);
}

.container + .container {
    margin-top: 4px;
}

.name {
    min-width: 35px;
    max-width: 35%;
    width: 35%;
    text-align: right;
    padding-right: 5px;
}

.progress {
    display: flex;
    align-items: center;
    min-width: 65%;
    max-width: 65%;
    width: 65%;
}

.main:not(.full) {
    min-width: 63.525%;
    max-width: 63.525%;
    width: 63.525%;
}

.bar {
    position: relative;
    display: block;
    height: 6px;
    border-radius: 3px;
    box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.07);
    overflow: hidden;
}

.line {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgb(46, 175, 190);
    background: -moz-linear-gradient(
        180deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: -webkit-linear-gradient(
        180deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: linear-gradient(
        180deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2eafbe",endColorstr="#2ac3bb",GradientType=1);
    box-shadow: inset 2px 2px 3px rgba(255, 255, 255, 0.3);
    border: 1px solid var(--color-primary);
    border-radius: 0 3px 3px 0;
    z-index: 1;
}

.container.availableSoon .line {
    background: rgb(222, 225, 225);
    background: -moz-linear-gradient(
        90deg,
        rgba(222, 225, 225, 1) 0%,
        rgba(248, 248, 248, 1) 100%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(222, 225, 225, 1) 0%,
        rgba(248, 248, 248, 1) 100%
    );
    background: linear-gradient(
        90deg,
        rgba(222, 225, 225, 1) 0%,
        rgba(248, 248, 248, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dee1e1",endColorstr="#f8f8f8",GradientType=1);
    border-color: var(--color-gray-light-2);
}

.counter {
    min-width: 36.475%;
    max-width: 36.475%;
    width: 36.475%;
    padding-left: 5px;
}

.goal {
    text-align: center;
    font-weight: 700;
}

@media (max-width: 319px) {
    .container {
        flex-direction: column;
    }

    .name,
    .progress {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }

    .name {
        text-align: left;
    }

    .main {
        flex: 1;
        min-width: auto;
        max-width: none;
        width: auto;
    }

    .counter {
        min-width: 68px;
        max-width: 68px;
        width: 68px;
        text-align: right;
    }
}

@media (min-width: 1200px) {
    .container {
        font-size: 22px;
        line-height: 24px;
    }

    .container + .container {
        margin-top: 12px;
    }

    .name {
        min-width: 25%;
        max-width: 25%;
        width: 25%;
        padding-right: 20px;
    }

    .progress {
        min-width: 75%;
        max-width: 75%;
        width: 75%;
    }

    .main:not(.full) {
        min-width: 73.33333333%;
        max-width: 73.33333333%;
        width: 73.33333333%;
    }

    .bar {
        height: 16px;
        box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.1);
    }

    .counter {
        min-width: 26.66666667%;
        max-width: 26.66666667%;
        width: 26.66666667%;
        padding-left: 20px;
    }
}
