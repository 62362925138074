.container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--color-white);
    padding: 9px 10px;
    margin: 0 -10px;
}

.main {
    color: var(--color-primary);
    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
}

.aside {
    padding-left: 10px;
}

.button {
    display: block;
    background: transparent;
    color: var(--color-error);
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    text-decoration: underline;
    white-space: nowrap;
    line-height: 16px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding: 0;
    cursor: pointer;
}

.button:hover,
.button:focus-visible {
    text-decoration: none;
}

.button[disabled] {
    pointer-events: none;
}

.field {
    position: relative;
}

.textarea {
    display: block;
    width: 100%;
    height: 190px;
    min-height: 190px;
    background: var(--color-white);
    color: var(--color-default);
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    border: 1px solid var(--color-primary);
    border-radius: 10px;
    box-shadow: none;
    outline: 0;
    padding: 10px;
    resize: none;
}

.textarea[disabled] {
    pointer-events: none;
}

.counter {
    color: var(--color-gray);
    font-size: 12px;
    line-height: 14px;
    margin: 8px 0 -3px;
}

.counter strong {
    font-weight: 600;
}

.error {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    bottom: 0;
    left: 15px;
    background: var(--color-error);
    color: var(--color-white);
    border-radius: 8px;
    padding: 15px 25px;
    z-index: 1;
}

.error p {
    margin: 0;
}

.error p + p {
    margin-top: 8px;
}

@media (min-width: 376px) {
    .container {
        border-radius: 10px;
    }
}

@media (min-width: 1200px) {
    .container {
        justify-content: flex-end;
        max-width: 700px;
        border-radius: 0;
        padding-right: 0;
        padding-left: 0;
        margin: 0 auto;
    }
    
    .container:before,
    .container:after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 1440px;
        background: var(--color-white);
        z-index: -2;
    }

    .container:before {
        right: 100%;
        border-radius: 10px 0 0 10px;
    }

    .container:after {
        left: 100%;
        border-radius: 0 10px 10px 0;
    }

    .aside {
        padding-left: 40px;
    }
}