.back {
    position: absolute;
    top: 10px;
    left: 10px;
    line-height: 0;
    z-index: 200;
}

.heading {
    text-align: center;
    margin-top: 40px;
}

.form {
    text-align: center;
}

.formButton {
    margin-top: 25px;
}

.info {
    overflow: hidden;
    margin-top: 23px;
}

.rules {
    font-size: 13px;
    font-weight: 300;
    line-height: 15px;
    text-align: center;
    margin: 0;
}

.link {
    color: var(--color-primary-dark);
    text-decoration: underline;
}

.remind {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    line-height: 18px;
    margin: 13px 0 0;
}

.info .remind {
    margin: 0;
}

.remindLink {
    color: var(--color-primary);
    text-decoration: underline;
}

.rulesLink:hover,
.remindLink:hover {
    text-decoration: none;
}
