.root {
    position: relative;
}

.label {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 90px;
    height: 90px;

    background-color: var(--color-white);
    border: 1px solid var(--color-primary);
    border-radius: 5px;
    transform: border-color 250ms ease;
}

.label > img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: relative;
}

.label > input[type="file"] {
    display: none;
}

.backgroundLayer {
    position: absolute;
    background: no-repeat center
        url("../../../assets/images/dashboard/answerOnDemand/add-photo.svg");
    inset: 0;
    transition: filter 250ms ease, background-color 250ms ease;
}

.backgroundLayer.filePresent {
    background-image: none;
}

.label.disabled {
    pointer-events: none;
    border-color: var(--color-gray);
}

.backgroundLayer.disabled {
    background-color: var(--color-gray-light-2);
    filter: grayscale(1);
}

.enlargeButton {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    border: none;
    margin: 0;
    padding: 3px;
    background: var(--color-primary-light);
    border-radius: 5px;
    box-shadow: var(--box-shadow-1);
    cursor: pointer;
}

.enlargeButton:disabled {
    cursor: default;
    filter: grayscale(1);
}

.enlargeButton > img {
    max-width: 100%;
}

.dialog {
    width: 100%;
    max-width: 1100px;
}

.previewImg {
    max-width: 100%;
}


@media (min-width: 1200px) {
    .label {
        width: 150px;
        height: 150px;
    }
}
