.container + .container {
    border-top: 3px solid var(--color-gray-light-4);
    padding-top: 10px;
    margin-top: 10px;
}

.heading {
    position: relative;
    display: block;
    width: 100%;
    background: transparent;
    color: var(--color-gray-dark);
    font-size: 16px;
    text-align: left;
    line-height: 18px;
    border: 0;
    border-radius: 0;
    outline: 0;
    box-shadow: none;
    padding: 10px 40px 10px 10px;
    pointer-events: none;
}

.heading.toggle {
    cursor: pointer;
    pointer-events: auto;
}

.name {
    display: block;
    font-size: 21px;
    line-height: 24px;
    margin: 0;
}

.class {
    display: block;
    font-style: italic;
    margin: 0;
}

.icon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 10px;
    height: 16px;
    padding: 0;
    margin-top: -8px;
}

.icon svg {
    width: auto;
    height: auto;
    max-height: 16px;
}

.buttons {
    display: flex;
    justify-content: center;
    padding-top: 5px;
    margin: 0 -5px;
}

.button {
    padding: 0 5px;
    margin-top: 10px;
}

.collapse {
    overflow: hidden;
}

.body {
    padding: 22px 0 10px;
}

@media (min-width: 1200px) {
    .container + .container {
        padding-top: 20px;
        margin-top: 20px;
    }

    .heading {
        padding-right: 30px;
    }

    .icon {
        right: 0;
    }
}
