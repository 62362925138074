.examTasksFinished {
    margin-bottom: 46px;
}

.title {
    font-size: 24px;
    line-height: 28px;
    color: var(--color-primary);
    text-align: center;
    margin-bottom: 10px;
}

.subtitle {
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;
    color: var(--color-primary);
    text-align: center;
    margin: -33px 0 30px;
}

.button {
    margin-top: 30px;
    margin-bottom: 20px;
    width: fit-content;
    margin-inline: auto;
}

@media (min-width: 1200px) {
    .title {
        font-size: 38px;
        line-height: 42px;
    }

    .subtitle {
        font-size: 30px;
        line-height: 34px;
        margin: 0 0 38px;
    }

    .tasks {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-flow: column;
        column-gap: 80px;
        background-color: var(--color-gray-light);
        border-radius: 20px;
        border: 1px var(--color-primary) solid;
        padding: 30px 20px 20px;
    }

    .button {
        margin-top: 40px;
    }
}
