.container,
.main {
    display: flex;
    flex-direction: column;
    flex: 1;
}

@media (min-width: 1200px) {
    .container {
        flex-direction: row;
        width: 100%;
        max-width: 790px;
        margin: 0 auto;
        padding-bottom: 80px;
    }

    .container.standalone {
        justify-content: center;
    }

    .container.group {
        align-items: flex-start;
        flex: none;
    }

    .container.standalone .main,
    .sidebar {
        min-width: 335px;
        max-width: 335px;
        width: 335px;
    }

    .container:not(.standalone) .main {
        margin-left: 20px;
    }

    .container:not(.standalone) .main:not(.multiple),
    .main .column {
        background: var(--color-gray-light);
        border: 1px solid var(--color-primary);
        border-radius: 20px;
        padding: 50px;
    }

    .main .column+.column {
        margin-top: 20px;
    }
}
