.container {
    display: flex;
}

.main {
    flex: 1;
}

.aside {
    padding-left: 60px;
}

.row {
    display: flex;
    margin: 0 -30px;
}

.column {
    flex: 1;
    padding: 0 30px;
}

.heading {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(46, 175, 190);
    background: -moz-linear-gradient(
        264deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: -webkit-linear-gradient(
        264deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: linear-gradient(
        264deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2eafbe",endColorstr="#2ac3bb",GradientType=1);
    color: var(--color-white);
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    line-height: 17px;
    border-radius: 10px;
    box-shadow: var(--box-shadow-1);
    padding: 9px 15px;
}

.container.disabled .heading {
    background: var(--color-gray);
}

.icon {
    display: flex;
    align-items: center;
    height: 17px;
    margin-right: 15px;
}

.icon img {
    width: auto;
    max-width: 30px;
    height: auto;
    max-height: 17px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background: var(--color-primary);
    border: 0;
    border-radius: 50%;
    box-shadow: none;
    outline: 0;
    padding: 0;
    cursor: pointer;
}

.button[disabled] {
    background: var(--color-gray);
    pointer-events: none;
}

.button svg {
    width: 13px;
    height: 13px;
}

.collapse {
    overflow: hidden;
    margin: 0 -30px;
    padding: 0 30px;
}
