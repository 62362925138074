.container {
    display: inline-flex;
    align-items: center;
}

.text {
    font-size: 16px;
    text-align: right;
    line-height: 18px;
}

.counter {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 38px;
    height: 38px;
    background: var(--color-primary);
    color: var(--color-white);
    font-size: 20px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    border-radius: 10px;
    padding: 0 8px;
    margin-left: 10px;
}

.container.visual .counter {
    padding: 0;
}

.counter svg {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 38px;
}

@media (min-width: 1200px) {
    .container {
        flex-direction: column-reverse;
    }

    .text {
        font-size: 18px;
        text-align: center;
        line-height: 20px;
        margin-top: 15px;
    }

    .counter {
        min-width: 50px;
        height: 50px;
        line-height: 50px;
        margin-left: 0;
    }

    .counter svg {
        max-height: 50px;
    }
}
