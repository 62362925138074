.container {
    margin-top: -20px;
}

.row {
    display: flex;
    margin: 0 -5px;
}

.column {
    flex: 1;
    padding: 0 5px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    line-height: 20px;
    border: 1px solid var(--color-white);
    border-radius: 10px;
    box-shadow: var(--box-shadow-2);
    cursor: pointer;
}

.button.active {
    border-color: var(--color-primary);
    pointer-events: none;
}

.buttonText {
    padding: 0 15px;
}

.title {
    color: var(--color-primary);
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin: 34px 0 32px;
}
