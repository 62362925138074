.button {
    position: relative;
    display: block;
    width: 100%;
    background: transparent;
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    line-height: 16px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding: 0;
    transition: background .2s ease;
    cursor: grab;
}

.button.critical {
    color: var(--color-error);
}

.button:before,
.button:after {
    content: "";
    position: absolute;
    left: 10px;
    right: 10px;
    border-bottom: 1px solid var(--color-gray-light-4);
    z-index: 1;
}

.button:before {
    top: 0;
}

.button:after {
    bottom: 0;
}

.button.disabled {
    background: var(--color-gray-light-3);
}

.button[disabled] {
    pointer-events: none;
}

.content {
    display: flex;
    align-items: center;
    padding: 10px;
    pointer-events: none;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    max-width: 32px;
    width: 32px;
    height: 32px;
}

.icon svg {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 32px;
}

.text {
    padding-left: 10px;
}

.slide {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    color: var(--color-white);
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    z-index: 1;
}

.slide:before {
    content: "";
    position: absolute;
    top: 50%;
    width: 15px;
    height: 8px;
    background-image: url(images/arrow-icon.svg);
    background-size: cover;
    background-position: center;
    margin-top: -4px;
    z-index: 1;
}

.slide.goTo {
    left: -100%;
    background: rgb(46, 175, 190);
    background: -moz-linear-gradient(
        263deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: -webkit-linear-gradient(
        263deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: linear-gradient(
        263deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2eafbe",endColorstr="#2ac3bb",GradientType=1);
    text-align: right;
    border-radius: 0 26px 26px 0;
    padding-right: 55px;
}

.slide.goTo:before {
    right: 20px;
}

.slide.markAsRead {
    left: 100%;
    background: var(--color-gray);
    text-align: left;
    border-radius: 26px 0 0 26px;
    padding-left: 55px;
}

.slide.markAsRead:before {
    left: 20px;
    transform: rotate(180deg);
}

.slideBody {
    display: flex;
    align-items: center;
    flex: 1;
    opacity: 0.4;
    transition: opacity .3s ease;
    pointer-events: none;
}

.slide.active .slideBody {
    opacity: 1;
}

.slide.goTo .slideBody {
    justify-content: flex-end;
}

.goToText {
    margin-right: 15px;
}

.slide.markAsRead .slideBody {
    justify-content: flex-start;
}

.markAsReadText {
    margin-left: 15px;
}

.goToIcon img,
.markAsReadIcon img {
    display: block;
    width: auto;
    height: auto;
}
