.button {
    display: inline-block;
    width: auto;
    min-width: 170px;
    background-image: none;
    background-color: var(--color-primary-light);
    color: var(--color-white);
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 20px;
    border: 1px solid transparent;
    border-radius: 23px;
    box-shadow: var(--box-shadow-2);
    outline: 0;
    padding: 12px 20px 11px;
    margin: 0;
    cursor: pointer;
}

.button:hover,
.button:focus {
    text-decoration: none;
}

.button.disabled {
    pointer-events: none;
}