.container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.forwardButton {
    text-align: center;
    padding-top: 15px;
}

.forwardButton a,
.forwardButton button {
    background: var(--color-error);
}

.footer {
    padding: 40px 0 10px;
    margin-top: auto;
}

.robot {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 214px;
}

.robot img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 214px;
}