.container {
    margin-bottom: 30px;
}

.option {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    background: var(--color-white);
    color: var(--color-black);
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    line-height: 18px;
    border: 1px solid var(--color-white);
    border-radius: 20px;
    box-shadow: var(--box-shadow-1);
    outline: 0;
    padding: 10px 40px 10px 20px;
    cursor: pointer;
}

.option[disabled] {
    pointer-events: none;
}

.option.multiple {
    padding-left: 40px;
}

.option + .option {
    margin-top: 10px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 14px;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    z-index: 1;
}

.icon svg {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 16px;
}

.content {
    display: flex;
    align-items: center;
}

.content p {
    margin: 0;
}

.content img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
}

.index {
    width: 10px;
}

.text {
    flex: 1;
    padding-left: 6px;
}

.checkbox {
    position: absolute;
    top: 50%;
    left: 20px;
    width: 20px;
    height: 20px;
    background: var(--color-white);
    border-radius: 5px;
    border: 1px solid var(--color-white);
    box-shadow: var(--box-shadow-1);
    overflow: hidden;
    margin-top: -10px;
    z-index: 1;
}

.checkboxIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.checkbox + .content {
    padding-left: 10px;
}

@media (min-width: 1200px) {
    .container {
        max-width: 355px;
        margin-right: auto;
        margin-left: auto;
    }
}
