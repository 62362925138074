.container {
    margin: 0 -10px;
    padding-bottom: 60px;
}

.title {
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    line-height: 20px;
    margin-bottom: 6px;
}

.list {
    border-top: 1px solid var(--color-primary-light-2);
    border-bottom: 1px solid var(--color-primary-light-2);
}

.empty {
    flex: 1;
    color: var(--color-gray-2);
    font-size: 16px;
    text-align: center;
    line-height: 18px;
    padding: 18px 10px 17px;
    margin: 0;
}

.loading {
    display: flex;
    justify-content: center;
    flex: 1;
    padding: 15px 10px 14px;
}

@media (min-width: 1200px) {
    .container {
        padding-bottom: 0;
    }
}
