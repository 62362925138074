.container {
    position: relative;
    display: flex;
    align-items: center;
    background: var(--color-white);
    border: 1px solid var(--color-white);
    padding: 9px 10px;
    margin-top: 15px;
}

.container.unconfirmed {
    border-color: var(--color-primary);
}

.content {
    flex: 1;
}

.name {
    color: var(--color-black);
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    margin: 0;
}

.container.unconfirmed .name {
    color: var(--color-gray);
    font-weight: 400;
}

.buttonGroup {
    display: flex;
    min-height: 34px;
    margin: 1px 0;
}

.button {
    padding-left: 10px;
}

.error {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    background: var(--color-error);
    color: var(--color-white);
    text-align: center;
    padding: 0 10px;
    z-index: 1;
}

.error p {
    margin: 0;
}
