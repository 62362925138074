.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 3000;
}

.modal:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-gray-light);
    opacity: 0.8;
    z-index: -1;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    padding: 10px;
}

.dialog {
    position: relative;
    width: 100%;
    max-width: 800px;
    background: var(--color-white);
    border: 1px solid var(--color-error);
    border-radius: 10px;
    box-shadow: var(--box-shadow-1);
}

.close {
    position: absolute;
    top: 14px;
    right: 14px;
    width: 30px;
    height: 30px;
    background: var(--color-error);
    border: 0;
    border-radius: 5px;
    box-shadow: none;
    opacity: .5;
    outline: 0;
    padding: 0;
    cursor: pointer;
}

.close:before,
.close:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border-bottom: 1px solid var(--color-white);
    width: 16px;
}

.close:before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.close:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.body {
    display: flex;
    color: var(--color-gray-dark);
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 18px;
    white-space: pre;
    overflow: auto;
    padding: 15px 0 15px 15px;
}

.error {
    padding-right: 30px;
}

.message {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
}

.stack {
    font-size: 12px;
    line-height: 14px;
    margin-top: 10px;
}