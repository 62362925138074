@media (min-width: 1200px) {
    .container {
        max-width: 700px;
        background: var(--color-white);
        border: 1px solid var(--color-primary);
        border-radius: 20px;
        padding: 20px 20px 40px;
        margin: 0 auto;
    }
}
