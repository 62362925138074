.container {
    background: var(--color-gray-3);
    border-radius: 20px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    text-decoration: none;
    line-height: 19px;
    border: 1px solid var(--color-white);
    border-radius: 20px;
    box-shadow: var(--box-shadow-1);
    outline: none;
    padding: 10px 20px;
    cursor: pointer;
}

.button.spaceBetween {
    justify-content: space-between;
}

.button.disabled {
    pointer-events: none;
}

.content {
    display: flex;
    align-items: center;
}

.icon {
    display: flex;
    align-items: center;
    height: 15px;
}

.icon svg {
    width: auto;
    max-width: 20px;
    height: auto;
    max-height: 15px;
}

.name {
    padding-left: 10px;
}

.progress {
    font-weight: 400;
}

.progress.low {
    color: var(--color-error);
}

.progress.medium {
    color: var(--color-warning);
}

.progress.high {
    color: var(--color-success);
}
