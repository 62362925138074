.container {
    position: relative;
    text-align: center;
    margin-bottom: 40px;
}

.container.smallSpacing {
    margin-bottom: 20px;
}

.container.mediumSpacing {
    margin-bottom: 30px;
}

.arrow {
    position: absolute;
    top: -1px;
    left: 0;
    line-height: 0;
}

.title {
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 40px;
}

.title.border {
    border-bottom: 1px solid rgba(46, 175, 190, 0.35);
    padding-bottom: 9px;
}

.subtitle {
    font-weight: 400;
    border-top: 1px solid rgba(46, 175, 190, 0.35);
    padding-top: 9px;
    margin-top: 9px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 50px;
    background: var(--color-white);
    border-radius: 20px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.075);
    margin: 0 auto 10px;
}

.icon img {
    width: auto;
    max-width: 200px;
    height: auto;
    max-height: 50px;
}

.class {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 54px;
    height: 68px;
    color: var(--color-white);
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    line-height: 1;
}

.class svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 54px;
    height: 68px;
    z-index: -1;
}

.class img {
    width: auto;
    max-width: 30px;
    height: auto;
}

@media (max-width: 1199px) {
    .class {
        position: absolute;
        top: -1px;
        right: 0;
        width: 24px;
        height: 30px;
        font-size: 12px;
    }

    .class svg {
        width: 24px;
        height: 30px;
    }

    .arrow.center,
    .class.center {
        top: 25px;
    }

    .class img {
        max-width: 16px;
    }
}

@media (min-width: 1200px) {
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container.hasShield:not(.hasIcon) {
        margin-top: -13px;
    }

    .container.smallSpacing.hasIcon {
        margin-bottom: 28px;
    }

    .title {
        padding: 0;
    }

    .container.hasShield .title {
        min-width: 594px;
        padding: 0 40px 0 94px;
    }

    .icon {
        height: 80px;
        width: 240px;
        margin-bottom: 20px;
    }

    .icon img {
        max-width: 240px;
        max-height: 80px;
    }
}
