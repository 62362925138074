.container {
    margin-bottom: 30px;
}

.field {
    display: flex;
    justify-content: center;
}

.radio {
    display: flex;
    background: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 20px;
    box-shadow: var(--box-shadow-1);
    padding: 2px;
    z-index: 1;
}

.button {
    position: relative;
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    line-height: 18px;
    background: transparent;
    border: 0;
    border-radius: 20px;
    box-shadow: none;
    outline: 0;
    padding: 3px 10px;
    cursor: pointer;
}

.button[disabled] {
    pointer-events: none;
}

.highlight {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-primary);
    border-radius: 20px;
    z-index: -1;
}
