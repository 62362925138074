.container {
    margin-bottom: -10px;
}

.empty {
    background: var(--color-gray-light-2);
    color: var(--color-black);
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    border-radius: 20px;
    box-shadow: var(--box-shadow-1);
    padding: 10px 20px;
    margin-bottom: 10px;
}