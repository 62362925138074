.container {
    text-align: center;
    margin-top: 50px;
}

.title {
    font-weight: 300;
    margin: 0 0 20px;
}

.tile {
    max-width: 101px;
    margin: 0 auto;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    background: var(--color-white);
    border-radius: 10px;
    box-shadow: var(--box-shadow-2);
}

.caption {
    color: var(--color-primary);
    font-size: 15px;
    font-weight: 700;
    line-height: 17px;
    margin-top: 5px;
}

.caption.error {
    color: var(--color-error);
}