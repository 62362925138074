.button {
    position: relative;
    display: block;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    line-height: 20px;
    box-shadow: var(--box-shadow-1);
    border-radius: 20px;
    padding: 10px 40px 10px 55px;
    margin-bottom: 10px;
    cursor: pointer;
}

.button.disabled {
    pointer-events: none;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100%;
    background: rgb(46, 175, 190);
    background: linear-gradient(
        224deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    border-radius: 20px;
    box-shadow: var(--box-shadow-2);
    padding: 4px;
}

.icon img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 32px;
}

.progress {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 35px;
    color: var(--color-error);
    font-size: 16px;
    text-align: center;
    line-height: 18px;
    border-radius: 9px;
    padding: 1px 0;
    margin-top: -8px;
    font-weight: 400;
}

.progress.low {
    background: var(--color-error);
}

.progress.medium {
    background: var(--color-warning);
}

.progress.high {
    background: var(--color-success);
}
