.button {
    position: relative;
    display: block;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    text-decoration: none;
    line-height: 20px;
    border: 0;
    border-radius: 10px;
    box-shadow: var(--box-shadow-2);
    padding: 20px 80px 20px 20px;
    cursor: pointer;
}

.button.active {
    background: rgb(46, 175, 190);
    background: -moz-linear-gradient(
        260deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: -webkit-linear-gradient(
        260deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: linear-gradient(
        260deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2eafbe",endColorstr="#2ac3bb",GradientType=1);
    color: var(--color-white);
}

.button.important {
    color: var(--color-error);
}

.button.disabled {
    pointer-events: none;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 50%;
    right: 25px;
    height: 30px;
    opacity: 0.3;
    margin-top: -15px;
}

.button.active .icon {
    opacity: 1;
}

.icon svg {
    width: auto;
    max-width: 50px;
    height: auto;
    max-height: 30px;
}

.loader {
    display: flex;
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -20px;
    scale: 0.75;
}
