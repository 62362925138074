.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 3000;
}

.modal:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-gray-light);
    opacity: 0.8;
    z-index: -1;
}

.wrapper {
    display: flex;
    min-height: 100%;
    padding: 10px;
}

.dialog {
    position: relative;
    width: 100%;
    background: var(--color-white);
    border: 1px solid var(--color-primary);
    border-radius: 10px;
    color: var(--color-gray-dark);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 18px;
    box-shadow: var(--box-shadow-1);
}

.close {
    position: absolute;
    top: 14px;
    right: 14px;
    width: 30px;
    height: 30px;
    background: var(--color-error);
    border: 0;
    border-radius: 5px;
    box-shadow: none;
    outline: 0;
    padding: 0;
    cursor: pointer;
}

.close:before,
.close:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border-bottom: 1px solid var(--color-white);
    width: 16px;
}

.close:before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.close:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.content {
    padding: 30px 20px;
}

@media (max-width: 1199px) {
    .dialog {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
    }
}

@media (min-width: 1200px) {
    .wrapper {
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
    }

    .dialog {
        max-width: 560px;
    }

    .content {
        padding: 40px 20px;
    }
}
