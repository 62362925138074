.container {
    padding-top: 10px;
}

.content {
    padding-top: 16px;
}

@media (min-width: 1200px) {
    .container {
        margin-right: -20px;
        margin-left: -20px;
    }

    .content {
        padding-right: 20px;
        padding-left: 20px;
    }
}
