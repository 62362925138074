.container {
    position: relative;
    z-index: 2;
}

.button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 92px;
    height: 46px;
    background-color: transparent;
    background-image: url(../../../../assets/images/navbar/brand-shape.svg);
    background-position: center;
    background-size: cover;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding: 0;
    cursor: pointer;
}

.button[disabled],
.button.disabled {
    pointer-events: none;
}

.text {
    position: absolute;
    top: -12px;
    left: 0;
    width: 100%;
    color: var(--color-primary);
    font-size: 10px;
    font-weight: 300;
    text-align: center;
    line-height: 12px;
    pointer-events: none;
}

.badge {
    position: absolute;
    bottom: -1px;
    left: 46px;
    background: var(--color-error);
    color: var(--color-white);
    font-size: 10px;
    line-height: 1;
    border-radius: 4px;
    padding: 1px 6px 2px;
}

.badge.premium {
    background: var(--color-primary);
}

@media (min-width: 1200px) {
    .container {
        display: flex;
        justify-content: center;
    }

    .button {
        width: auto;
        height: 50px;
        background-image: none;
    }

    .badge {
        bottom: -13px;
        left: 78px;
        font-size: 14px;
        padding: 1px 10px;
    }

    .badge.premium {
        left: 61px;
        padding: 0 10px 2px;
    }
}
