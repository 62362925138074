.back {
    position: absolute;
    top: 10px;
    left: 10px;
    line-height: 0;
    z-index: 200;
}

.heading {
    text-align: center;
}

.forwardButton {
    text-align: center;
    margin-top: 40px;
}

.forwardButton button {
    min-width: 230px;
}

@media (min-width: 1200px) {
    .heading {
        margin-bottom: 51px;
    }

    .heading h2 {
        font-size: 32px;
        line-height: 36px;
    }

    .forwardButton {
        margin-top: 56px;
    }
}
