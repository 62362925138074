.heading {
    text-align: center;
    margin-top: 40px;
}

.forwardButton {
    text-align: center;
    margin-top: 40px;
}

.forwardButton a {
    min-width: 230px;
}