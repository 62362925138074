.container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.element {
    position: fixed;
    z-index: -1;
}

.element.left {
    top: 0;
    left: 0;
}

.element.left.bottom {
    top: auto;
    bottom: 0;
}

.element.right {
    bottom: 0;
    right: 0;
}
