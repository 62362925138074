.container {
    position: relative;
    display: flex;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 16px;
    border: 1px solid var(--color-primary);
    border-radius: 28px;
    padding: 0;
    margin-bottom: 20px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 54px;
    max-width: 54px;
    width: 54px;
    background: var(--color-white);
    border: 1px solid var(--color-primary);
    border-radius: 28px;
    box-shadow: var(--box-shadow-1);
    margin: -1px 0 -1px -1px;
}

.content {
    flex: 1;
    padding: 15px 25px 15px 15px;
}

.content p {
    margin: 0;
}

.content p + p {
    margin-top: 9px;
}

.content a {
    color: inherit;
    font-weight: 600;
    text-decoration: none;
}

@media (max-width: 1199px) {
    .icon svg path {
        fill: var(--color-primary);
    }
}

@media (min-width: 1200px) {
    .container {
        align-items: center;
        min-height: 56px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        line-height: 18px;
        border-width: 2px;
        border-radius: 14px;
        padding: 20px;
        margin-bottom: 30px;
    }

    .icon {
        height: 54px;
        background: var(--color-primary);
        border: 0;
        box-shadow: none;
    }

    .content {
        padding: 0 0 0 20px;
    }

    .content p + p {
        margin-top: 13px;
    }

    .content a {
        font-weight: 700;
    }
}
