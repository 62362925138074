.field {
    position: relative;
}

.label {
    position: absolute;
    top: 14px;
    left: 0;
    width: 100%;
    color: var(--color-gray);
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    line-height: 18px;
    pointer-events: none;
    padding: 0 20px;
    z-index: 2;
}

.label.center {
    text-align: center;
    padding: 0 10px;
}

.input {
    display: block;
    position: relative;
    width: 100%;
    height: 45px;
    background: var(--color-white);
    color: var(--color-gray-dark);
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    line-height: 18px;
    border: 1px solid var(--color-white);
    border-radius: 23px;
    outline: 0;
    box-shadow: var(--box-shadow-2);
    padding: 13px 20px 12px;
    cursor: pointer;
}

.input.center {
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
}

.input[disabled] {
    pointer-events: none;
}

.icon {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -2.5px;
}

.icon svg {
    display: block;
    width: 8px;
    height: auto;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 185px;
    background: var(--color-white);
    border: 1px solid var(--color-primary);
    border-top: 0;
    border-radius: 0 0 18px 18px;
    box-shadow: var(--box-shadow-1);
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 100;
}

.menu {
    list-style: none;
    padding: 8px 0;
    margin: 0;
}

.menuButton {
    display: block;
    width: 100%;
    background: transparent;
    color: var(--color-gray);
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 18px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding: 5px 10px;
    transition: background .3s ease, color .3s ease;
    cursor: pointer;
}

.menuButton.active,
.menuButton:hover {
    background: var(--color-primary);
    color: var(--color-white);
}

.menuButton[disabled] {
    pointer-events: none;
}