.container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(46, 175, 190);
    background: -moz-linear-gradient(
        264deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: -webkit-linear-gradient(
        264deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    background: linear-gradient(
        264deg,
        rgba(46, 175, 190, 1) 0%,
        rgba(42, 195, 187, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2eafbe",endColorstr="#2ac3bb",GradientType=1);
    padding: 7px 10px 7px 20px;
    margin: 0 -10px 5px;
    z-index: 1;
}

.title {
    color: var(--color-white);
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    margin: 2px 0 3px;
}

.loading {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -12px;
}

.icon {
    padding-left: 10px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 27px;
    background: var(--color-white);
    border: 0;
    border-radius: 14px;
    box-shadow: none;
    outline: 0;
    padding: 0;
    cursor: pointer;
}

.button[disabled] {
    pointer-events: none;
}

.button img,
.button svg {
    width: auto;
    max-width: 24px;
    height: auto;
    max-height: 17px;
}

.tooltip {
    position: absolute;
    bottom: 100%;
    right: 0;
    margin-bottom: 4px;
}

.tooltipBody {
    display: block;
    min-width: 160px;
    background: var(--color-primary-light);
    color: var(--color-white);
    font-size: 16px;
    text-align: center;
    line-height: 18px;
    border-radius: 10px;
    box-shadow: var(--box-shadow-1);
    padding: 11px 15px;
}

.tooltipArrow {
    display: block;
    width: 16px;
    border-width: 12px 8px 0;
    border-style: solid;
    border-color: var(--color-primary-light) transparent transparent;
    margin-left: auto;
    margin-right: 17px;
}

@media (min-width: 376px) {
    .container {
        border-radius: 10px;
    }
}

@media (min-width: 1200px) {
    .container {
        justify-content: center;
        padding: 10px 50px;
        margin: 0 0 10px;
    }

    .container.roundingDisabled {
        border-radius: 0;
    }

    .container.equal {
        height: calc(100% - 51px);
    }

    .title {
        font-size: 27px;
        line-height: 30px;
        text-align: center;
        margin: 0;
    }

    .icon {
        position: absolute;
        top: -10px;
        right: -10px;
        padding: 0;
    }

    .button {
        width: 50px;
        height: 50px;
        border: 1px solid var(--color-primary);
        border-radius: 10px;
        box-shadow: var(--box-shadow-1);
    }

    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        top: -10px;
        right: -10px;
        width: 50px;
        height: 50px;
        background: var(--color-white);
        border: 1px solid var(--color-primary);
        border-radius: 10px;
        box-shadow: var(--box-shadow-1);
        margin-top: 0;
    }
}
