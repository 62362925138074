.container {
    position: relative;
    text-align: center;
}

.button {
    overflow: hidden;
}

.icon {
    display: inline-block;
    width: 16px;
    height: 12px;
    margin-left: -10px;
    margin-right: 10px;
}

.icon svg {
    display: block;
    width: auto;
    max-width: 100%;
    height: 12px;
}