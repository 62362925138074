.container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    line-height: 16px;
    border: 0;
    border-radius: 15px;
    box-shadow: var(--box-shadow-1);
    outline: 0;
    padding: 7px 18px;
    transition: background .3s ease, color .3s ease, opacity .3s ease;
    cursor: pointer;
}

.container[disabled] {
    pointer-events: none;
}

.container:hover {
    background: var(--color-primary);
    color: var(--color-white);
}

.icon {
    display: flex;
    align-items: center;
    height: 16px;
    margin-right: 6px;
}

.icon svg {
    width: auto;
    max-width: 20px;
    height: auto;
    max-height: 16px;
}

.container .icon svg path {
    transition: fill .3s ease;
}

.container:hover .icon svg path {
    fill: var(--color-white);
}

.loading {
    display: block;
}