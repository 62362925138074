.select {
    max-width: 335px;
    margin: 0 auto 30px;
}

.info {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 20px;
}

.accordions {
    margin-top: 28px;
}

.progress {
    margin-bottom: 20px;
}

.empty {
    color: var(--color-gray-2);
    font-size: 14px;
    text-align: center;
    line-height: 16px;
    padding-bottom: 10px;
}

@media (min-width: 1200px) {
    .select {
        padding-top: 12px;
        margin-bottom: 50px;
    }
    
    .info {
        column-gap: 60px;
        row-gap: 30px;
    }

    .accordions {
        background: var(--color-white);
        border-radius: 10px;
        box-shadow: var(--box-shadow-1);
        margin: 56px 0 50px;
    }

    .progress {
        max-width: 720px;
        margin-right: auto;
        margin-left: auto;
    }

    .buttons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 30px;
        row-gap: 20px;
        max-width: 1120px;
        margin: 0 auto;
    }

    .empty {
        font-size: 21px;
        line-height: 24px;
        padding-bottom: 40px;
    }
}