.button {
    display: flex;
    align-items: center;
    width: 100%;
    background: var(--color-white);
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    line-height: 18px;
    border: 1px solid var(--color-white);
    border-radius: 20px;
    box-shadow: var(--box-shadow-1);
    padding: 11px 20px;
    cursor: pointer;
}

.button[disabled] {
    background: var(--color-gray-light);
    color: var(--color-gray);
    border-color: var(--color-gray);
    pointer-events: none;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    max-width: 20px;
    width: 20px;
    height: 20px;
    background: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 5px;
    box-shadow: var(--box-shadow-1);
    cursor: pointer;
}

.icon.rounded {
    border-radius: 50%;
}

.button[disabled] .icon {
    background: var(--color-gray-light);
}

.mark {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
}

.mark svg {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 12px;
}

.radio {
    width: 12px;
    height: 12px;
    background: var(--color-primary);
    border-radius: 50%;
}

.label {
    padding-left: 20px;
}

.labelAddon {
    display: block;
    font-weight: 300;
}

@media (min-width: 1200px) {
    .button {
        padding: 26px 30px;
    }
}
