.container {
    background: var(--color-white);
    box-shadow: var(--box-shadow-1);
    border: 1px solid var(--color-white);
    border-radius: 20px;
    margin-bottom: 10px;
}

.button {
    position: relative;
    width: 100%;
    background: transparent;
    color: var(--color-black);
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    line-height: 18px;
    border: 0;
    border-radius: 20px;
    padding: 10px 41px 10px 19px;
    cursor: pointer;
}

.arrow {
    position: absolute;
    top: 50%;
    right: 14px;
    width: 14px;
    height: 14px;
    margin-top: -7px;
}

.arrow svg {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 14px;
}

.collapse {
    overflow: hidden;
}

.content {
    padding: 10px 10px 19px;
}

.content p:first-child {
    margin-top: -3px;
}

@media (min-width: 1200px) {
    .container {
        border-color: var(--color-gray);
    }

    .content {
        border-top: 1px solid var(--color-gray);
        padding: 30px 20px 26px;
    }

    .content figure {
        margin-right: -20px;
        margin-left: -20px;
    }

    .content figure:first-child {
        margin-top: -30px;
    }
}
