.step1 {
    max-width: 630px;
    margin-inline: auto;
}

.heading {
    text-align: center;
}

.subHeading {
    text-align: center;
    margin-top: 20px;
    max-width: 290px;
    margin-inline: auto;
}

.nextButton {
    margin-top: 63px;
    margin-inline: auto;
    width: fit-content;
    padding-bottom: 50px;
}

@media (min-width: 1200px) {
    .subHeading {
        font-size: 24px;
        line-height: 27px;
    }
}
