.empty {
    background: var(--color-gray-light-2);
    color: var(--color-black);
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    line-height: 20px;
    border-radius: 20px;
    box-shadow: var(--box-shadow-1);
    padding: 10px 20px;
    margin-bottom: 10px;
}

@media (min-width: 1200px) {
    .container {
        background: var(--color-gray-light);
        border: 1px solid var(--color-primary);
        border-radius: 20px;
        padding: 17px 20px 20px;
        margin: 30px 0 50px;
    }

    .progress {
        max-width: 700px;
        margin: 0 auto;
    }

    .tasks {
        display: grid;
        grid-auto-columns: 33.3333%;
        grid-auto-flow: column;
        margin: 0 -40px;
        padding-bottom: 20px;
    }

    .task {
        margin: 0 40px;
    }

    .empty {
        width: calc(300% + 160px);
    }
}
