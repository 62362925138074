.container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(46, 175, 190, .4);
    padding-bottom: 50px;
}

.main {
    min-width: 51.953125%;
    max-width: 51.953125%;
    width: 51.953125%;
    padding-right: 50px;
}

.heading h3 {
    color: var(--color-black);
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    margin: 0 0 5px;
}

.heading h2 {
    color: var(--color-black);
    font-size: 38px;
    font-weight: 400;
    line-height: 42px;
    margin: 0 0 29px;
}

.content {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 31px;
}

.content p {
    margin: 0 0 9px;
}

.aside {
    min-width: 48.046875%;
    max-width: 48.046875%;
    width: 48.046875%;
}

.thumbnail {
    background-color: var(--color-gray-light-2);
    background-size: cover;
    background-position: center;
    box-shadow: var(--box-shadow-1);
    padding-bottom: 65.0406504%;
    border-radius: 20px;
    overflow: hidden;
}