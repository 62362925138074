.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    background: #f8f9fa;
    border-radius: 10px;
    box-shadow: var(--box-shadow-2);
    padding: 4px;
    transition: opacity 0.3s ease;
    cursor: pointer;
}

.button.disabled {
    pointer-events: none;
}

.button:hover {
    opacity: 0.7;
}

.icon {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 40px;
}
