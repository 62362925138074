.heading {
    margin-top: -60px;
}

.input {
    margin-top: 10px;
}

.loading {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    margin-top: 36px;
}

.loading p {
    margin: 0 0 20px;
}

@media (min-width: 1200px) {
    .container {
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
    }
}
