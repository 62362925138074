.button {
    position: relative;
    display: block;
    background: var(--color-white);
    color: var(--color-black);
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    text-decoration: none;
    line-height: 18px;
    border: 1px solid var(--color-white);
    border-radius: 20px;
    box-shadow: var(--box-shadow-1);
    padding: 10px 20px;
    margin-bottom: 10px;
    cursor: pointer;
}

.button.disabled {
    pointer-events: none;
}

.badge {
    position: absolute;
    top: -5px;
    left: -5px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 14px;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    z-index: 1;
}

.difficulty {
    position: absolute;
    top: 50%;
    right: 14px;
    line-height: 0;
    margin-top: -4px;
}

.loading {
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -12px;
}