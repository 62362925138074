.container {
    display: flex;
    align-items: center;
    min-height: 55px;
    border-top: 1px solid var(--color-primary-light-2);
    border-bottom: 1px solid var(--color-primary-light-2);
    padding: 9px 0 8px;
}

.container.borderless {
    border-top: 0;
}

.container + .container {
    margin-top: -1px;
}

.main {
    flex: 1;
    color: var(--color-gray-2);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 18px;
    padding-left: 10px;
}

.aside {
    min-width: 110px;
    text-align: right;
    padding-right: 10px;
}

.status {
    color: var(--color-success);
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    margin: 0;
}

.status.inactive {
    color: var(--color-error);
}
