.examTaskDetails {
    position: relative;
    margin-bottom: 46px;
    flex-grow: 1;
}

.infinity {
    display: none;
    position: absolute;
    right: clamp(-180px, (100vw - 1360px) / (-2), 0px);
    bottom: 30px;
    height: 466px;
    z-index: -1;
}

@media (min-width: 1200px) {
    .infinity {
        display: unset;
    }

    .tasks {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-flow: column;
        column-gap: 87px;

        background-color: var(--color-gray-light);
        border-radius: 20px;
        border: 1px var(--color-primary) solid;
        padding: 30px 20px;
    }
}
