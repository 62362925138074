.container {
    position: relative;
    background: var(--color-primary);
    color: var(--color-white);
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    padding: 7px 10px;
    border-radius: 7px;
    z-index: 1000;
}

.container.error {
    background: var(--color-error);
}

.arrow {
    border-style: solid;
}

.arrow.top {
    top: 100%;
    border-width: 6px 6px 0;
    border-color: var(--color-primary) transparent transparent;
}

.container.error .arrow.top {
    border-color: var(--color-error) transparent transparent;
}

.arrow.right {
    right: 100%;
    border-width: 6px 6px 6px 0;
    border-color: transparent var(--color-primary) transparent transparent;
}

.container.error .arrow.right {
    border-color: transparent var(--color-error) transparent transparent;
}

.arrow.bottom {
    bottom: 100%;
    border-width: 0 6px 6px;
    border-color: transparent transparent var(--color-primary);
}

.container.error .arrow.bottom {
    border-color: transparent transparent var(--color-error);
}

.arrow.left {
    left: 100%;
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent var(--color-primary);
}

.container.error .arrow.left {
    border-color: transparent transparent transparent var(--color-error);
}
